/* eslint-disable no-unused-vars */
import { useState, useRef, useImperativeHandle } from "react";
import { useEffect } from "react";


// Material UI
import { ThemeProvider } from "@emotion/react";
import { Box, Modal } from "@mui/material";

// React Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
//check
// Helmet
import Helmet from "react-helmet";
import './components/Notification/sweetalert2-custom.css';

// Modules
import IndividualNft from "./Pages/Collections/Individualnft";
import MyBidsContainer from "./Pages/MyBids/MyBidsContainer";
import ArtCardDetails from "./components/ArtCardDetails/ArtCardDetails";
import Layout from "./components/Layout/Layout";
import useCustomTheme from "./hooks/useCustomTheme";
import Auction from "./Pages/Auction/Auction";
import Explore from "./Pages/Explore/Explore";
import Favourites from "./Pages/Favourites/Favourites";
import TrendingSellers from "./Pages/TrendingSellers/TrendingSellers";
import Home from "./Pages/Home/Home";
import SellersDetails from "./Pages/SellerDetails/SellersDetails";
import UserProfile from "./Pages/UserProfile/UserProfile";
import TrendingCreators from "./Pages/TrendingCreators/TrendingCreators";
import ProfileInterface from "./components/ProfileInterface/ProfileInterface";
import KYCInterface from "./components/KYCInterface/KYCInterface";
import LanguageInterface from "./components/LanguageInterface/LanguageInterface";
import ThemeInterface from "./components/ThemeInterface/ThemeInterface";
import EditProfile from "./components/ProfileInterface/EditProfile";
import KYCPending from "./components/KYCInterface/KYCPending";
import KYCApproved from "./components/KYCInterface/KYCApproved";
import TermsAndCondition from "./Pages/Terms&Condition/TermsAndCondition";
import FAQ from "./Pages/FAQ/FAQ";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CreateAssets from "./Pages/CreateAssets/CreateAssets";
import DummyUserProfile from "./Pages/DummyUser/DummyUserProfile";
import AuctionCardDetails from "./components/AuctionCardDetails/AuctionCardDetails";
import CreatorsDetails from "./Pages/CreatorsDetails/CreatorsDetails";
import AnimatedLoader from "./Utils/AnimatedLoader/AnimatedLoader";
import XNFTLoader_dark from "./assets/loader_gif.gif";
import XNFTLoader_light from "./assets/Xwhite BG.gif";

// CSS Styles
import "./App.css";
import SignUpPage from "./Pages/Authentication/SignUpPage/SignUpPage";
import AuthInterface from "./Pages/Authentication/AuthInterface";
import SignInPage from "./Pages/Authentication/SignInPage/SignInPage";
import VerifyEmailPage from "./Pages/Authentication/VerifyEmailPage/VerifyEmailPage";
import SetPasswordPage from "./Pages/Authentication/SetPasswordPage/SetPasswordPage";
import AuthProvider from "./context/AuthProvider";
import ChangePasswordInterface from "./components/ChangePasswordInterface/ChangePasswordInterface";
import ChangeEmailInterface from "./components/ChangeEmailInterface/ChangeEmailInterface";
import Collections from "./Pages/Collections/Collections";

import MarketplaceAbi from "./contractData/MarketplaceAbi.json";
import MarketplaceAddress from "./contractData/MarketplaceAddress.json";
import SingleArtWork from "./components/ArtCardDetails/SingleArtWork";
import { DataProvider } from "./Pages/Collections/DataContext";
import OldCollection from "./Pages/Collections/OldCollection";
import Allbrands from "./Pages/Brands/Allbrands";
import Addbrand from "./Pages/Brands/Addbrand";
import Viewbrand from "./Pages/Brands/Viewbrand";
import UserProfileCollection from "./Pages/Collections/UserProfileCollection";
import Profile from "./Pages/Profile/Profile";
import { getUserWallets } from "./service/api";
import Redeem from "./Pages/Redeem/Redeem";
import Store from "./Pages/Store/Store";
import RedeemGreeting from "./Pages/Redeem/RedeemGreeting";
import ConvertXNFT from "./Pages/ConvertXNFT/ConvertXNFT";
import ConvertXnftCollection from "./Pages/Collections/ConvertXnftCollection";
import RedeemXNFT from "./Pages/Redeem/RedeemXNFT";
const ethers = require('ethers');

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const { customTheme } = useCustomTheme(darkMode);

  const [showApp, setShowApp] = useState(false);
  const [account, setAccount] = useState(null);
  // const [nft, setNFT] = useState({});
  const [marketplace, setMarketplace] = useState({});
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(false);
  const [blockchain, setBlockchain] = useState("");
  const [indexxWalletEmail, setIndexxWalletEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState('');
  const [indexxWalletObj, setIndexWalletObj] = useState('');
  const [isIndexxWalletWeb, setIsIndexxWalletWeb] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  async function handleConnectMetamaskWallet() {
    setIsMetamask(true);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccount(accounts[0]);
    // Get provider from Metamask
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(provider);
    // Set signer
    const signer = provider.getSigner();

    // window.ethereum.on("chainChanged", (chainId) => {
    //   window.location.reload();
    // });

    console.log('I AM HERE', blockchain)
    if (blockchain === "Polygon") {
      await switchToPolygonMainnet();
    } else if (blockchain === "Ethereum") {
      await ethMain();
    }
    // window.ethereum.on("accountsChanged", async function (accounts) {
    //   setAccount(accounts[0]);
    //   await handleConnectMetamaskWallet();
    // });
    loadContracts(signer);
  }

  async function handleConnectIndexxExtensionWallet() {
    console.log('i am here in handleConnectIndexxExtensionWallet');
  }

  async function ethMain() {
    const chainId = '0x1'; // Chain ID for Ethereum mainnet

    if (typeof window.ethereum !== 'undefined') {
      try {
        // Try to switch to Ethereum Mainnet
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (error) {
        console.error('Error switching to Ethereum Mainnet:', error);
      }
    } else {
      console.error('Ethereum provider is not available');
    }
  }

  async function switchToSepoliaTestnet() {
    const chainId = '0xaa36a7'; // Replace with Sepolia's chain ID

    if (typeof window.ethereum !== 'undefined') {
      try {
        // Try to switch to Sepolia testnet if it's already added to MetaMask
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError) {
        // If the network hasn't been added to MetaMask, add it
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId,
                chainName: 'Sepolia Network',
                nativeCurrency: {
                  name: 'Sepolia',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: ['https://rpc2.sepolia.org'], // Replace with the actual RPC URL for Sepolia network
                blockExplorerUrls: ['https://sepolia.etherscan.io'], // Replace with the actual block explorer URL for Sepolia network
              }]
            });
          } catch (addError) {
            console.error('Error adding Sepolia Testnet:', addError);
          }
        } else {
          console.error('Error switching to Sepolia Testnet:', switchError);
        }
      }
    } else {
      console.error('Ethereum provider is not available');
    }
  }

  async function switchToPolygonMainnet() {
    const chainId = '0x89'; // Chain ID for Polygon mainnet

    if (typeof window.ethereum !== 'undefined') {
      try {
        // Try to switch to Polygon Mainnet if it's already added to MetaMask
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError) {
        // If the network hasn't been added to MetaMask, add it
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId,
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18
                },
                rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
                blockExplorerUrls: ['https://explorer.matic.network/'],
                iconUrls: ['https://matic.network/static/img/favicon.png']
              }]
            });
          } catch (addError) {
            console.error('Error adding Polygon Mainnet:', addError);
          }
        } else {
          console.error('Error switching to Polygon Mainnet:', switchError);
        }
      }
    } else {
      console.error('Ethereum provider is not available');
    }
  }

  async function switchToMumbaiTestnet() {
    const chainId = '0x13881'; // Chain ID for Mumbai testnet

    if (typeof window.ethereum !== 'undefined') {
      try {
        // Try to switch to Mumbai testnet if it's already added to MetaMask
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError) {
        // If the network hasn't been added to MetaMask, add it
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId,
                chainName: 'Mumbai Testnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18
                },
                rpcUrls: ['https://rpc-mumbai.matic.today'],
                blockExplorerUrls: ['https://explorer-mumbai.maticvigil.com/'],
                // Optional: you might need to provide an icon URL if you have one
                iconUrls: ['https://matic.network/static/img/favicon.png']
              }]
            });
          } catch (addError) {
            console.error('Error adding Mumbai Testnet:', addError);
          }
        } else {
          console.error('Error switching to Mumbai Testnet:', switchError);
        }
      }
    } else {
      console.error('Ethereum provider is not available');
    }
  }

  const loadContracts = async (signer) => {
    // Get deployed copies of contracts
    const marketplace = new ethers.Contract(
      MarketplaceAddress.address,
      MarketplaceAbi.abi,
      signer
    );
    setMarketplace(marketplace);

    setLoading(false);
  };

  useEffect(() => {
    const appLoader = setTimeout(() => setShowApp(true), 2000);
    return () => clearTimeout(appLoader);
  }, []);


  useEffect(async () => {
    if (!isIndexxWalletWeb && isMetamask)
      if (blockchain === "Polygon") {
        await switchToPolygonMainnet();
      } else if (blockchain === "Ethereum") {
        await ethMain();
      }
  }, [blockchain])

  const handleDarkThemeSwitch = () => {
    localStorage.setItem("theme", "dark");
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    }
  };

  const handleLightThemeSwitch = () => {
    localStorage.setItem("theme", "light");
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  };

  const handleConnectIndexxWallet = async (
    email = '',
    returnUrl = '',
    blockchainFromUrl = '',

  ) => {
    if (email === '' || returnUrl === '') {
      handleCloseModal();
      let currentUrl = window.location.href;
      window.open(
        'https://wallet.indexx.ai/login/sign-in?referrer=xnftmarketplace&currentUrl=' +
        currentUrl +
        '?blockchain=' +
        blockchain,
        '_self'
      );
    } else {
      const res = await getUserWallets(email);
      localStorage.setItem('isIndexxWebWallet', true);
      localStorage.setItem('indexxWebWallets', JSON.stringify(res.data));
      localStorage.setItem('indexxWebWalletEmail', email);
      localStorage.setItem('returnUrl', returnUrl);
      localStorage.setItem('blockchainFromUrl', blockchain);
      const selectedBlockchain = blockchain === "Ethereum" ? "Ethereum" : "Polygon"
      const requiredWallet = res.data.find((x) => x.coinName === selectedBlockchain);
      console.log('i am here in app')
      setIndexWalletObj(requiredWallet);
      if (requiredWallet) {
        setWalletAddress(requiredWallet.coinWalletAddress);
        setIsIndexxWalletWeb(true);
        if (blockchainFromUrl)
          setBlockchain(blockchainFromUrl);
        setIndexxWalletEmail(email);
      } else {
        console.log('Not registered on indexx wallet');
      }
    }
  };

  const ref = useRef();
  // Expose the handleConnectIndexxWallet function through the ref
  useImperativeHandle(ref, () => ({
    handleConnectIndexxWallet,
  }));

  useEffect(() => {
    const theme = localStorage.getItem("theme");

    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  }, [customTheme]);

  const connectwall = useRef(null);

  return (
    <AuthProvider>
      <ThemeProvider theme={customTheme}>
        <Helmet
          bodyAttributes={
            darkMode
              ? { style: "background-color: #040404" }
              : { style: "background-color: #ffffff" }
          }
        />

        <div
          style={{
            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
            height: "100vh",
          }}
        >
          {showApp ? (
            <Box bgcolor={darkMode ? "#040404" : "#ffffff"}>
              <BrowserRouter>
                <DataProvider>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account}  blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Home darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} forwardedRef={ref}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Home darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/mybids"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <MyBidsContainer darkMode={darkMode} />
                        </Layout>
                      }
                    />

                    <Route
                      path="/authentication"
                      element={<AuthInterface darkMode={darkMode} />}
                    >
                      <Route
                        path="sign-up"
                        element={<SignUpPage darkMode={darkMode} />}
                      />
                      <Route
                        path="sign-in"
                        element={<SignInPage darkMode={darkMode} />}
                      />
                      <Route
                        path="verify-email"
                        element={<VerifyEmailPage darkMode={darkMode} />}
                      />
                      <Route
                        path="set-password"
                        element={<SetPasswordPage darkMode={darkMode} />}
                      />
                    </Route>
                    <Route
                      path="/explore"
                      element={
                        // <Layout darkMode={darkMode} handleConnecMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Explore darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/auction"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Auction darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} forwardedRef={ref}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Collections darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/explore/:name/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} forwardedRef={ref}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} connectwall={connectwall}>
                          <ArtCardDetails darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} account={account} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} connectwall={connectwall} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/auction/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <AuctionCardDetails darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} account={account} handleConnectIndexxWallet={handleConnectIndexxWallet} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/favourites"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Favourites darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-creators/:uid/collection/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-sellers/:uid/collection/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/view-profile/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Profile darkMode={darkMode} mode="INUSER" blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/view-profile/:id/:contractAddress/:tokenId"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <UserProfileCollection darkMode={darkMode} mode="INUSER" blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user/profile/collection/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <IndividualNft darkMode={darkMode} mode="INUSER" blockchain={blockchain} setBlockchain={setBlockchain} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user/profile/:contractAddress/:id"
                      element={
                        //<Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <UserProfileCollection darkMode={darkMode} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections/:collection/:id"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <IndividualNft darkMode={darkMode} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/collections/:collection/:id/:name/:subId"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} connectwall={connectwall}>
                          <OldCollection darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} account={account} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} connectwall={connectwall} />
                        </Layout>
                      }
                    />
                    {/* <Route
                    path="/collections/:collection/:id/:name/:subId"
                    element={
                      <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <SingleArtWork darkMode={darkMode} mode={"OFUSER"} blockchain={blockchain} setBlockchain={setBlockchain} />
                      </Layout>
                    }
                  /> */}
                    <Route
                      path="/trending-sellers"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <TrendingSellers darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-sellers/:id"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <SellersDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/user/profile"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <DummyUserProfile darkMode={darkMode} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/create-asset"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <CreateAssets darkMode={darkMode} marketplace={marketplace} account={account} provider={provider} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/redeem-gift"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Redeem darkMode={darkMode} marketplace={marketplace} account={account} provider={provider} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    {/* <Route
                      path="/store-gift"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Store darkMode={darkMode} />
                        </Layout>
                      }
                    /> */}
                    <Route
                      path="/redeem-greeting"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <RedeemGreeting darkMode={darkMode} marketplace={marketplace} account={account} provider={provider} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/redeem-xnft"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <RedeemXNFT darkMode={darkMode} marketplace={marketplace} account={account} provider={provider} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    {/* <Route
                      path="/store-greeting"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Store darkMode={darkMode} />
                        </Layout>
                      }
                    /> */}
                    <Route
                      path="/profile"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <UserProfile darkMode={darkMode} />
                        </Layout>
                      }
                    >
                      <Route
                        path="user-profile"
                        element={<ProfileInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="edit-profile"
                        element={<EditProfile darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc"
                        element={<KYCInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc-pending"
                        element={<KYCPending darkMode={darkMode} />}
                      />
                      <Route
                        path="kyc-approved"
                        element={<KYCApproved darkMode={darkMode} />}
                      />
                      <Route
                        path="language"
                        element={<LanguageInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="change-password"
                        element={<ChangePasswordInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="change-email"
                        element={<ChangeEmailInterface darkMode={darkMode} />}
                      />
                      <Route
                        path="theme"
                        element={
                          <ThemeInterface
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                            handleDarkThemeSwitch={handleDarkThemeSwitch}
                            handleLightThemeSwitch={handleLightThemeSwitch}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/trending-creators"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <TrendingCreators darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/trending-creators/:id"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <CreatorsDetails darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/brands"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          {/* <Allbrands darkMode={darkMode}  blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail}  indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb}/> */}
                          <Allbrands darkMode={darkMode} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/convert-xnft"
                      element={
                        // <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <ConvertXNFT darkMode={darkMode} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/convert-xnft/:contractAddress/:id"
                      element={
                        //<Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain}>
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <ConvertXnftCollection darkMode={darkMode} mode={"OFUSER"} ccount={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-brand"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Addbrand darkMode={darkMode} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/brands/:id"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} setIndexxWalletEmail={setIndexxWalletEmail} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <Viewbrand darkMode={darkMode} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet} indexxWalletEmail={indexxWalletEmail} indexxWalletObj={indexxWalletObj} indexxWalletAddress={walletAddress} isIndexxWalletWeb={isIndexxWalletWeb} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/privacy-policy"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <PrivacyPolicy darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/terms-and-condition"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <TermsAndCondition darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/frequently-asked-questions"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <FAQ darkMode={darkMode} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/contact-us"
                      element={
                        <Layout darkMode={darkMode} handleConnectMetamaskWallet={handleConnectMetamaskWallet} marketplace={marketplace} account={account} blockchain={blockchain} setBlockchain={setBlockchain} handleConnectIndexxWallet={handleConnectIndexxWallet}>
                          <ContactUs darkMode={darkMode} />
                        </Layout>
                      }
                    />
                  </Routes>
                </DataProvider>
              </BrowserRouter>
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {/* <img width="350px" height="350px" src={XNFTLoader} alt="loading..." /> */}

              {darkMode ? (
                <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
              ) : (
                <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
              )}

            </div>
          )}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
