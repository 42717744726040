/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import Createxnft from '../../components/Create/Nice';
import CustomCover from './CustomCover';
import CustomTokenCombo from './CustomTokenCombo';
import CustomTokenCover from './CustomTokenCover';
import xnftlogo from './ftop.png';
import info from '../../assets/info.png'
import { GradientButtonBlue, GradientButtonPrimary } from '../../Utils/GradientButtons/GradientButtons';
import { useTheme } from '@emotion/react';
import { Typography, Stack, useMediaQuery, Box  } from '@mui/material';
import BTC from '../../assets/tokenImages/BTC.svg';
import ETH from '../../assets/tokenImages/ETH.svg';
import { AiFillWallet } from "react-icons/ai";
import AWS from 'aws-sdk';
import axios from 'axios';
// import html2canvas from 'html2canvas';
// import { exportComponentAsPNG } from 'react-component-export-image';

import styles from "./SingleArtWork.module.css";
import { FiChevronLeft } from "react-icons/fi";

import { useRef } from 'react';
import { uploadFile } from 'react-s3';
import { BigNumber } from 'ethers';
import SwalNotification from '../../components/Notification/SwalNotification';
import Swal from 'sweetalert2';
const S3_BUCKET = 'indexx-exchange';
const REGION = 'ap-northeast-1';
const { v4: uuidv4 } = require('uuid');
AWS.config.update({
  accessKeyId: 'AKIA5FBFFKSZD76C64G6',
  secretAccessKey: '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',
  region: REGION,
});
// var s3Bucket = new AWS.S3({ params: { Bucket: S3_BUCKET } });

var s3 = new AWS.S3();
const API = axios.create({
  baseURL: 'https://sorekt.indexx.ai/',
});
window.Buffer = window.Buffer || require('buffer').Buffer;

const CreateAssets = ({
  darkMode,
  handleConnectMetamaskWallet,
  marketplace,
  account,
  blockchain,
  setBlockchain,
  handleConnectIndexxWallet
}) => {
  const componentCoverRef = useRef(null);
  const [comboCount, setComboCount] = useState(0);
  const isMintEnabled = comboCount > 0;
  const [parentSelectedToken, setParentSelectedToken] = useState('');
  const [prices, setPrices] = useState([]);
  const [coverImage, setCoverImage] = useState('');
  const [showCustomTokenCover, setShowCustomTokenCover] = useState(false);

  const [parentCompanyName, setParentCompanyName] = useState('');
  const [parentTokenCoverPrice, setParentTokenCoverPrice] = useState('');
  const [parentWebsiteName, setParentWebsiteName] = useState('');
  const [parentSerialNumber, setParentSerialNumber] = useState('');

  const [parentImage, setParentImage] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  // const [companyName, setCompanyName] = useState('Indexx');
  // cover
  const [coverColor, setCoverColor] = useState('#5A5A5A');

  const [royaltyFee, setRoyaltyFee] = useState(0);
  const [coverDescription, setCoverDescription] = useState('');
  const [coverName, setCoverName] = useState('');
  const [topImage, setTopImage] = useState(null);

  //combo cover
  const [comboCoverColor, setComboCoverColor] = useState('#5A5A5A');
  const [comboCoverTitle, setComboCoverTitle] = useState('');
  //const [comboCoverSerial, setComboCoverSerial] = useState('');
  const [comboCoverDescription, setComboCoverDescription] = useState(0);
  const [comboCoverDescriptionArr, setComboCoverDescriptionArr] = useState([]);
  const [comboCollectionId, setComboCollectionId] = useState('');
  const [comboDescriptions, setComboDescriptions] = useState([]);
  const [comboPrices, setComboPrices] = useState([]);
  const [comboSerialNumbers, setComboSerialNumbers] = useState([]);
  const [comboMetadatURls, setComboMetadatURls] = useState([]);
  const [collectionCreated, setCollectionCreated] = useState(false);
  const [coverCreated, setCoverCreated] = useState(false);
  const [comboCoverCreated, setComboCoverCreated] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleParentTokenCoverPrice = (newTokenCoverPrice) => {
    setParentTokenCoverPrice(newTokenCoverPrice);
  };



  if (parentImage != null) {
    console.log("image exists inside parent");
  }
  const handleParentImage = (newImage) => {
    setParentImage(newImage)
  }

  console.log("parent serial: ", parentSerialNumber);
  const handleParentSerialNumber = (newSerialNumber) => {
    setParentSerialNumber(newSerialNumber);
  }

  console.log('parent token:', parentSelectedToken);
  const handleParentTokenChange = (newToken) => {
    setParentSelectedToken(newToken);
  };



 // Parse the query parameters from the URL when the component mounts
 useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const returnedFrom = urlParams.get('returnFrom');
  const blockchainFromUrl = urlParams.get('blockchain');
  console.log(handleConnectIndexxWallet);
  const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
  const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
  const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
  const lsreturnUrl = localStorage.getItem('returnUrl');
  const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
  if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
          console.log('I am here')
          handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
          console.log('else if am here')
          handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
  }
}, [blockchain]);


  console.log('parent companyName:', parentCompanyName);
  const handleParentCompanyNameChange = (newName) => {
    setParentCompanyName(newName);
  };

  console.log('parent websiteName:', parentWebsiteName);
  const handleParentWebsiteNameChange = (newWebsite) => {
    setParentWebsiteName(newWebsite);
    setParentCompanyName(newWebsite);
  };

  const handleAddCombo = () => {
    if (comboCount < 4) {
      setComboCount(comboCount + 1);
    }
  };

  const handleMint = () => {
    // Logic for minting
    console.log('minting here')
  };

  const handleDeleteCombo = () => {
    if (comboCount > 0) {
      setComboCount(comboCount - 1);
    }
    prices.pop();
    console.log(prices);
  };

  const handlePriceChange = (index, newPrice) => {
    console.log(index, newPrice)
    const updatedPrices = [...prices];
    updatedPrices[index] = newPrice;
    setPrices(updatedPrices);
  };
  const handleCompanyNameChange = (event) => {
    console.log('I am here company name')
    setParentCompanyName(event.target.value);
  };
  const handleChangeCoverColor = (newColor) => {
    // setColor(newColor.hex);
    setCoverColor(newColor.hex);
    console.log(newColor);
  };
  const handleChangeCoverRoyaltyFee = (e) => {
    // setColor(newColor.hex);
    setRoyaltyFee(e.target.value);
    console.log(e.target.value, 'in asset');
  };
  const handleChangeCoverDescriptionParent = (e) => {
    setCoverDescription(e.target.value);
    console.log(e.target.value, 'in asset');
  };
  const handleChangeCoverNameParent = (e) => {
    setCoverName(e.target.value);
    console.log(e.target.value, 'in asset');
  };
  const handleComboCoverSerialNumberChangeParent = (e) => {
    //setComboCoverSerial(e.target.value);
    console.log(e.target.value, 'in asset');
  };
  const handleComboCoverTitleChangeParent = (e) => {
    setComboCoverTitle(e.target.value);
    console.log(e.target.value, 'in asset');
  };
  const handleComboCoverColorChangeParent = (newColor) => {
    // setColor(newColor.hex);
    setComboCoverColor(newColor.hex);
    console.log(newColor);
  };
  const handleComboCoverDescriptionChangeParent = (e) => {
    setComboCoverDescription(e.target.value);
    setComboCoverDescriptionArr([...e.target.value])
    console.log(e.target.value);
  };

  const renderCustomCombos = () => {
    try {
      const customCombos = [];
      for (let i = 0; i < comboCount; i++) {
        customCombos.push(
          <div key={i}>
            <br />
            <br />
            <br />
            <br />
          </div>
        );
        customCombos.push(
          <CustomTokenCombo
            key={`combo-${i}`}
            count={i}
            style={{ marginTop: 40 }}
            handlePriceChange={(newPrice) => handlePriceChange(i, newPrice)}
            companyName={parentCompanyName}
            websiteName={parentWebsiteName}
            serialNumber={parentSerialNumber}
            parentImage={parentImage}
          />
        );
      }
      return customCombos;
    } catch (err) {
      console.log(err);
    }
  };

  function validateFields() {
    if (!account) {
      // alert('connect wallet');
      SwalNotification( darkMode, "Please Connect your wallet", "");
      return false;
    }
    if (!coverImage) {
      // alert('upload cover image');
      SwalNotification( darkMode, "Please upload cover image", "");
      return false;
    }
    console.log(parentCompanyName, royaltyFee);
    // Validation checks
    let isValid = true;
    let errors = '';

    // Validate cover name
    if (!coverName) {
      isValid = false;
      errors += '\nCover name is required.';
    }

    // Validate description
    if (!coverDescription) {
      isValid = false;
      errors += '\nDescription is required.';
    }

    // Validate royalty fee
    if (!royaltyFee) {
      isValid = false;
      errors += '\nPlease enter a Royalty Fee.';
    }
    if (royaltyFee > 10) {
      isValid = false;
      errors += 'Royalty Fee should be less 10% only';
    }
    // Validate company name
    // if (!parentCompanyName) {
    //   isValid = false;
    //   errors += '\nEnter Company name.';
    // }
    if (!parentSerialNumber) {
      isValid = false;
      errors += '\nEnter Serial Number.';
    }

    // Check if all validations passed
    if (isValid) {
      if (parentCompanyName === 'Indexx') {
        console.log('I am here');
        // alert('Confirm your Company');
        SwalNotification( darkMode, "Please Confirm your Company", "");
        return false;
      }
    } else {
      // Display validation errors to the user
      // alert(' ' + errors);
      SwalNotification( darkMode, errors, "");
      return false;
    }
    return true;
  }
  async function handleCreateCollection() {
    // validation
    if (!validateFields()) {
      console.log('validation', validateFields())
      return 0;
    }

    // setShowCustomTokenCover(true); // handleAddComboCover todo
    // alert('Under devlopment');
    // return 0;

    try {
      // input image to aws
      let imageURL = await uploadInputImageToAws(coverImage);
      console.log(imageURL);

      //input company image to aws
      let companyImageUrl = await uploadInputImageToAws(companyImage);
      console.log(companyImageUrl);
      // componenet to image to aws
      let imageXNFTCover = await coverComponentToImage(
        // 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/e3e7723f-aefb-49ef-8fdb-aa672b67bc79.image/png'
        imageURL,
        companyImageUrl
      );
      console.log(imageXNFTCover);

      // create collection
      console.log(marketplace, marketplace.provider);
      console.log(coverName, parentCompanyName, royaltyFee * 10);
      let collectionId;
      if (!collectionCreated) {
        await (
          await marketplace.createCollection(
            coverName,
            parentCompanyName,
            royaltyFee * 10
          )
        ).wait();
        setCollectionCreated(true);

        collectionId = await marketplace.collectionCounter();
        console.log(collectionId);
        collectionId = parseInt(collectionId.toString());
        console.log(collectionId);
        setComboCollectionId(collectionId)
      }
      //metadata
      const metadataURL = await uploadMetadata(
        // 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/e3e7723f-aefb-49ef-8fdb-aa672b67bc79.image/png',
        imageXNFTCover,
        1,
        account,
        coverName
      );

      // const metadataURL =
      // 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/Indexx.ai_%2Cxb2b3%2C6%2Cba%2Cd43744637a286fb876d81.json';

      console.log(metadataURL);
      // mint
      if (!coverCreated) {
        let mintTxn = await marketplace.createCover(
          collectionId,
          coverDescription,
          String(Math.floor(Math.random() * 100000) + 1), //add this we don't need serial number
          metadataURL
        );
        console.log('collectionId', collectionId);
        await marketplace.provider
          .waitForTransaction(mintTxn.hash)
          .then(async function (mintTxn) {
            console.log(mintTxn); // see what you data is
            console.log(mintTxn.logs); // see what you data is
            console.log(mintTxn.logs[0].topics); // see what you data is
            // add combo cover
            let msg = 'Cover Created Successfully' + collectionId;
            // alert(msg);
            SwalNotification( darkMode, msg, "");
            // setShowCustomTokenCover(true); // handleAddComboCover todo
            return collectionId;
          });
        setCoverCreated(true);
      }
      return collectionId;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
  async function uploadInputImageToAws(imageURL) {
    const config = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: 'AKIA5FBFFKSZD76C64G6',
      secretAccessKey: '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',
    };
    console.log(config);
    console.log(imageURL);
    let res = await uploadFile(imageURL, config);
    console.log(res);
    return res.location;
  }

  async function coverComponentToImage(imageURL, companyImageUrl) {
    console.log(imageURL, parentSelectedToken);
    return new Promise(async (resolve, reject) => {
      try {
        const canvas = document.createElement('canvas');
        canvas.id = 'canvasId';
        const ctx = canvas.getContext('2d');

        // Set the canvas dimensions
        canvas.width = 520;
        canvas.height = 872;
        // canvas.width = 360;
        // canvas.height = 700;
        ctx.fillStyle = coverColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        //if you need logo
        // const localImage1 = new Image();
        // localImage1.src = xnftlogo;
        // localImage1.onload = async () => {
        //xnft logo
        // ctx.drawImage(localImage1, 95, 33, 318, 75); // y,x

        // const ctx = canvas.getContext("2d");
        console.log('serial number', parentSerialNumber);
        let text = parentSerialNumber;
        console.log(text);
        text = `${text}`.padStart(7, '0');
        ctx.font = 'bold 40px Poppins';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        console.log(parentCompanyName, parentCompanyName.length);
        // ctx.fillText(
        //   parentCompanyName,
        //   (canvas.width - parentCompanyName.length) / 2,
        //   50,
        //   318,
        //   75
        // ); // y,x
        ctx.fillText(text, (canvas.width - text.length) / 2, 738);

        ctx.font = '20px Poppins';
        // const websiteName = 'www.indexx.ai';
        const websiteName = parentWebsiteName;
        console.log(
          websiteName,
          websiteName.length,
          ctx.measureText(websiteName).width
        );

        ctx.fillText(
          websiteName,
          15 + (canvas.width - websiteName.length) / 2,
          820
        );

        // Load the second local image
        const localImage2 = new Image();
        localImage2.src = parentSelectedToken === 'btc' ? BTC : ETH;
        localImage2.onload = () => {
          // Draw the second local image on the canvas
          ctx.drawImage(localImage2, 127, 110, 258, 258);
          let img2url;
          canvas.toBlob((blob) => {
            // Use the blob for your needs, for example, upload it to a server
            img2url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            console.log(link);
            link.download = `logo.png`;
            link.href = img2url;
            // link.click();
          });

          //Load the parent/ company image
          const companayImage2 = new Image();
          companayImage2.src = companyImageUrl;

          companayImage2.crossOrigin = 'anonymous';


          companayImage2.onload = () => {

            // Draw the second local image on the canvas
            ctx.drawImage(localImage2, 184, 110, 258, 258);
            let img2url;
            canvas.toBlob((blob) => {
              // Use the blob for your needs, for example, upload it to a server
              img2url = URL.createObjectURL(blob);
              const link = document.createElement('a');

              console.log(link);
              link.download = `company_logo.png`;
              link.href = img2url;
              // link.click();
            });
            // fetch(parentCompanyName).then((response) => {
            //   console.log(parentCompanyName);

            // Draw the company local image on the canvas
            //ctx.drawImage(companayImage2, 20, 110, 258, 258);

            ctx.drawImage(
              companayImage2,
              127,
              // 420,
              // sizeOfImage,
              // sizeOfImage
              10, 140, 50
            );

            canvas.toBlob((blob) => {
              // Use the blob for your needs, for example, upload it to a server
              img2url = URL.createObjectURL(blob);
              const link = document.createElement('a');

              console.log(link);
              link.download = `logo.png`;
              link.href = img2url;
              // link.click();
            });


            // Load the web image
            const webImage = new Image();

            webImage.src = imageURL;
            console.log(imageURL);

            webImage.crossOrigin = 'anonymous';
            console.log(imageURL);

            try {
              webImage.onload = async () => {

                fetch(imageURL).then((response) => {
                  console.log(imageURL);

                  // Draw the web image on the canvas
                  const sizeOfImage = 270;
                  console.log('response upload image', response, sizeOfImage, (520 - sizeOfImage) / 2);
                  ctx.drawImage(
                    webImage,
                    (520 - sizeOfImage) / 2,
                    420,
                    sizeOfImage,
                    sizeOfImage
                  );

                  // Convert the canvas to a blob

                  try {
                    canvas.toBlob(async (blob) => {
                      img2url = URL.createObjectURL(blob);
                      const link = document.createElement('a');

                      link.download = `original.png`;
                      link.href = img2url;
                      // link.href = canvas.toDataURL('image/png');

                      link.click();
                      console.log('DOwnloaded');
                      // return;
                      console.log('Uploading to ipfs', img2url);
                      const generateRandom = uuidv4();
                      const uploadToS3 = await uploadImageToS3FromUrl(
                        img2url,
                        generateRandom
                      );
                      console.log('mediaHash ', imageURL, uploadToS3);
                      resolve(uploadToS3);
                    });
                  } catch (error) {
                    console.log('error in final', error);
                    // xNFTComponentToImage(imageURL);
                    reject('');
                    // setMsg('Try to Mint Again');
                    // setIsModalOpen(true);
                    // setIsMintLoading(false);
                  }
                });
              };

            } catch (error) {
              console.log(error);
            }

          };

        }

      } catch (err) {
        console.log(err)
      }
    })

  }

  async function uploadImageToS3FromUrl(url, randomValue) {
    return new Promise((resolve, reject) => {
      // Fetch the image using Axios
      axios
        .get(url, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          // Convert the image to a Buffer and get its content type
          const imageBuffer = Buffer.from(response.data, 'binary');
          const contentType = response.headers['content-type'];
          console.log(contentType);
          // Set up the S3 upload parameters
          const uploadParams = {
            Bucket: S3_BUCKET,
            Key: randomValue + '.' + contentType,
            Body: imageBuffer,
            ContentType: contentType,
          };
          s3.upload(uploadParams, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data.Location);
            }
          });
        });
    });
  }

  async function uploadMetadata(imageURL, curNftNumber, account, name) {
    let response = await API.post(
      // "http://localhost:5200/openai/metadataURL",
      '/openai/metadataURL',
      {
        gifUrl: imageURL,
        nftNumber: curNftNumber,
        address: account,
        description: name,
      }
    );
    console.log(response);
    // console.log(response.Location);
    console.log(response.data.data.Location);
    const metadataUri = response.data.data.Location;
    return metadataUri;
  }

  // for combo cover
  async function handleCreateComboCover() {
    // under development for brian to test
    // alert('Under devlopment');
    handleAddCombo();
    //return;
    const collectionId = await handleCreateCollection(); // todo prob in returning the value
    // if (!isError) return;
    // let collectionId = await marketplace.collectionCounter();
    // validation of combocovers
    console.log(collectionId);
    if (collectionId === 0) {
      // alert('XNFT Cover was not created');
      return;
    }
    console.log(collectionId);

    // if (!comboCoverSerial) {
    //   alert('Enter serial number for combo cover');
    //   return;
    // }
    if (!comboCoverTitle) {
      // alert('Enter title for combo cover');
      SwalNotification( darkMode, "Please Enter title for combo cover", "");
      return;
    }
    if (!comboCoverDescription) {
      // alert('Enter description for combo cover');
      SwalNotification( darkMode, "Please Enter description for combo cover", "");
      return;
    }

    // alert('Under devlopment');
    // setShowCustomTokenCover(true); // handleAddComboCover todo

    // return;

    try {
      // componenet to image to aws
      let imageXNFTCover = await comboCoverComponentToImage();
      console.log(
        'done',
        imageXNFTCover,
        parentTokenCoverPrice,
        comboCoverDescription,
        parentSerialNumber
      );

      // metadata
      const metadataURL = await uploadMetadata(
        // 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/e3e7723f-aefb-49ef-8fdb-aa672b67bc79.image/png',
        imageXNFTCover,
        2,
        account,
        coverName
      );

      // const metadataURL =
      //   'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/sapphire_%2Cx358d61423d%2C66158bc118%2Cc481ca733c277b564c2.json';
      console.log(metadataURL);

      // mint
      let mintTxn = await marketplace.createComboCover(
        BigNumber.from(parseInt(collectionId.toString())),
        comboCoverDescription,
        String(Math.floor(Math.random() * 100000) + 1), //add this we don't need serial number,
        metadataURL,
        BigNumber.from(parentTokenCoverPrice)
      );
      marketplace.provider
        .waitForTransaction(mintTxn.hash)
        .then(async function (mintTxn) {
          console.log(mintTxn); // see what you data is
          console.log(mintTxn.logs); // see what you data is
          console.log(mintTxn.logs[0].topics); // see what you data is
          // add combo cover
          // alert('Combo Cover Created Successfully');
          SwalNotification( darkMode, "Combo Cover Created Successfully", "");
          handleAddCombo();
        });
      setComboCoverCreated(true);
    } catch (error) {
      console.log(error);
    }
  }


  async function finalComboMint() {

    
    let randomNumbers = [];
    for (let i = 0; i < prices.length; i++) {
      randomNumbers.push(String(Math.floor(Math.random() * 100000) + 1))
    }
    console.log(
      BigNumber.from(parseInt(comboCollectionId.toString())),
      setComboCoverDescriptionArr,
      prices,
      randomNumbers, //added this we don't need serial number,
      comboMetadatURls,
    )
    /*
    let mintTxn = await marketplace.createCombos(
      BigNumber.from(parseInt(comboCollectionId.toString())),
      setComboCoverDescriptionArr,
      prices,
      randomNumbers, //added this we don't need serial number,
      comboMetadatURls,
    );
    marketplace.provider
      .waitForTransaction(mintTxn.hash)
      .then(async function (mintTxn) {
        console.log(mintTxn); // see what you data is
        console.log(mintTxn.logs); // see what you data is
        console.log(mintTxn.logs[0].topics); // see what you data is
        // add combo cover
        alert('Combo Cover Created Successfully');
        handleAddCombo();
      });
      */
  }

  async function comboCoverComponentToImage() {
    console.log(
      'done started',
      parentSelectedToken,
      prices,
      parentTokenCoverPrice
    );
    return new Promise(async (resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.id = 'canvasId';
      const ctx = canvas.getContext('2d');

      // Set the canvas dimensions
      canvas.width = 520;
      canvas.height = 872;
      // canvas.width = 360;
      // canvas.height = 700;
      ctx.fillStyle = comboCoverColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#171C26';

      ctx.fillRect(0, 0, 520, 120);

      console.log('serial number', parentSerialNumber);
      let text = parentSerialNumber;
      console.log(text);
      text = `${text}`.padStart(7, '0');
      ctx.font = 'bold 40px Poppins';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'white';

      // company name
      console.log(parentCompanyName, parentCompanyName.length);
      ctx.fillText(
        parentCompanyName,
        (canvas.width - parentCompanyName.length) / 2,
        60
      ); // y,x

      ctx.fillText(text, (canvas.width - text.length) / 2, 738);

      // title
      text = comboCoverTitle;
      ctx.font = '34px Poppins';
      ctx.fillText(text, (canvas.width - text.length) / 2, 650);

      // const websiteName = 'www.indexx.ai';
      ctx.font = '20px Poppins';
      const websiteName = parentWebsiteName;
      console.log(
        websiteName,
        websiteName.length,
        ctx.measureText(websiteName).width
      );

      ctx.fillText(
        websiteName,
        15 + (canvas.width - websiteName.length) / 2,
        810
      );

      // Load the second local image
      const localImage2 = new Image();
      localImage2.src = parentSelectedToken === 'btc' ? BTC : ETH;
      localImage2.onload = () => {
        // Draw the second local image on the canvas
        ctx.drawImage(localImage2, 104, 269, 314, 314);
        let img2url;
        canvas.toBlob(async (blob) => {
          // Use the blob for your needs, for example, upload it to a server
          img2url = URL.createObjectURL(blob);
          const link = document.createElement('a');

          console.log(link);
          link.download = `logo.png`;
          link.href = img2url;
          link.click();
          console.log('Uploading to ipfs', img2url);
          const generateRandom = uuidv4();
          const uploadToS3 = await uploadImageToS3FromUrl(
            img2url,
            generateRandom
          );
          console.log('mediaHash ', uploadToS3);
          resolve(uploadToS3);
        });
      };
      // };
    });
  }

  return (
    <Box 
      mt={isMobile ? 2 : 11.5}
    >
            {!isMobile ? 
      <Box className={styles.topNavigationBox}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => window.history.back()}
              >
                <FiChevronLeft fontSize={"1.5rem"} color={darkMode?"#ffffff" : "#121212"}/>
              </Box>
              <Typography
                className={styles.topNavigationTypo}
                component="div"
               
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ cursor: "pointer" }}
                  zIndex={2}
                  color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                  onClick={() => window.history.back()}
                >
                Create Asset
                </Typography>
              
              </Typography>
            </Box>
      :null}
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height:"auto"
      }}
    >



      {/* <React.Fragment>
        <ComponentToPrint ref={componentCoverRef} />
        <button onClick={() => exportComponentAsPNG(componentCoverRef)}>
          Export As
        </button>
      </React.Fragment> */}
      <Box
      sx={{
        width: `${!isMobile ? 'auto' : "326px" }`,
      }}
      >
        <CustomCover
          tokenPrice={parentTokenCoverPrice}
          selectedToken={parentSelectedToken}
          prices={prices}
          setTitleImage={setCoverImage}
          handleTokenChange={handleParentTokenChange}
          companyName={parentCompanyName}
          handleCompanyNameChange={handleParentCompanyNameChange}
          websiteName={parentWebsiteName}
          parentSerialNumber={parentSerialNumber}
          handleWebsiteNameChange={handleParentWebsiteNameChange}
          handleCompanyNameChangeParent={handleCompanyNameChange}
          handleChangeCoverColor={handleChangeCoverColor}

          handleChangeCoverRoyaltyFee={handleChangeCoverRoyaltyFee}
          handleChangeCoverNameParent={handleChangeCoverNameParent}
          handleChangeCoverDescriptionParent={
            handleChangeCoverDescriptionParent
          }
          handleParentSerialNumber={handleParentSerialNumber}
          handleParentImage={handleParentImage}
          style={{ margin: '40px auto' }}
          setTopImage={setTopImage}
          topImage={topImage}
          setCompanyImage={setCompanyImage}
          companyImage={companyImage}
        />
        <br />
        <br />
        <br />
        <br />
        {true && (
          <CustomTokenCover
            selectedToken={parentSelectedToken}
            companyName={parentCompanyName}
            websiteName={parentWebsiteName}
            tokenPrice={parentTokenCoverPrice}
            serialNumber={parentSerialNumber}
            parentImage={parentImage}
            handleTokenPrice={handleParentTokenCoverPrice}
            handleSerialNumberChangeParent={
              handleComboCoverSerialNumberChangeParent
            }
            handleTitleChangeParent={handleComboCoverTitleChangeParent}
            handleColorChangeParent={handleComboCoverColorChangeParent}
            handleDescriptionChangeParent={
              handleComboCoverDescriptionChangeParent
            }
          />
        )}

      </Box>
      {renderCustomCombos()}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '50px',
          marginBottom: '20px',
          width: `${!isMobile ? 'auto' : "326px" }`,
        }}
      >
        <Stack 
          direction={"row"}
          spacing={3}>
          <GradientButtonBlue
            onClick={handleCreateComboCover}
            disabled={comboCount === 5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
              // width: 428,
              width: `${!isMobile ? '428px' : "149px" }`,
            }}
          >
            <br /> <br />
            <Typography variant="subtitle1" component="span" color="secondary.main" >
              Add Combo
            </Typography>
          </GradientButtonBlue>
          <GradientButtonBlue
            onClick={handleDeleteCombo}
            disabled={comboCount === 0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
              // width: 428,
              width: `${!isMobile ? '428px' : "153px" }`,
              padding:"0",
            }}
          >
            <br /> <br />
            <Typography variant="subtitle1" component="span" color="secondary.main">
              Delete Combo
            </Typography>
          </GradientButtonBlue>
        </Stack>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: '20px',
          width: `${!isMobile ? 'auto' : "326px" }`,
      }}
      >
        <GradientButtonBlue
          onClick={handleMint}
          disabled={!isMintEnabled}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            height: 50,
            width: 880,
          }}
        >
          <br /> <br />
          <Typography variant="subtitle1" component="span" color="secondary.main" pr={1} pl={1}>
            Mint
          </Typography>
        </GradientButtonBlue>
      </Box>
    </Box>
    </Box>

  );
};

export default CreateAssets;