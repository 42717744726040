/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Avatar,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import axios from "axios";
import SellerDetailsCard from "../../components/SellerDetailsCard/SellerDetailsCard";
import btclogo from "../../assets/collectionImages/BTClogo.png";
import ETHlogo from "../../assets/collectionImages/eth new PP lpgo 1.svg";
import USDlogo from "../../assets/collectionImages/USDlogo.png";
import ArtCardFB from "../../components/Skeletons/ArtCardFB";
import CollectionCardFB from "../../components/Skeletons/CollectionCardFB";

import Backdrop from "../../assets/exploreBackDropCircle.svg";
import Slider from "react-slick";

import styles from "./SingleArtWork.module.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import AddIcon from "@mui/icons-material/Add";
import ProfileCover from "../../assets/userProfileCover.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CreateCollection from "../../components/CreateCollection/CreateCollection";
import CollectionCard from "./CollectionCard/CollectionCard";
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import contractAddress from "../../contractData/contractsAddress.json";
import { getAllNFTMinted } from '../../service/api';
import { useLocation } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import USDCollectionCard from './CollectionCard/USDCollectionCard';

// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MultiCollectionPage = ({ darkMode, blockchain, setBlockchain, handleConnectIndexxWallet }) => {
  const { t } = useTranslation();

  const [sellerData, setSellerData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const [tabValue, setTabValue] = useState(0); // setting tab value for changing

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [name, setName] = useState("");
  const [bitcoinBlackNFTs, setBitcoinBlackNFTs] = useState([]);
  const [bitcoinPurpleNFTs, setBitcoinPurpleNFTs] = useState([]);
  const [bitcoinBlueNFTs, setBitcoinBlueNFTs] = useState([]);
  const [bitcoinGreenNFTs, setBitcoinGreenNFTs] = useState([]);
  const [bitcoinPinkNFTs, setBitcoinPinkNFTs] = useState([]);
  const [bitcoinOrangeNFTs, setBitcoinOrangeNFTs] = useState([]);
  const [ethereumBlackNFTs, setEthereumBlackNFTs] = useState([]);
  const [ethereumPurpleNFTs, setEthereumPurpleNFTs] = useState([]);
  const [ethereumBlueNFTs, setEthereumBlueNFTs] = useState([]);
  const [ethereumGreenNFTs, setEthereumGreenNFTs] = useState([]);
  const [ethereumPinkNFTs, setEthereumPinkNFTs] = useState([]);
  const [ethereumOrangeNFTs, setEthereumOrangeNFTs] = useState([]);
  const [usdNFTs, setUsdNFTs] = useState([]);
  const [isBitcoin, setIsBitcoin] = useState(false);
  const [isEthereum, setIsEthereum] = useState(false);
  const [isXUSD, setIsXUSD] = useState(false);
  const navigationRef = useRef(null);
  // const [sold, setsold] = useState();

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          initialSlide: 2.2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const trendingSlideRef = useRef();

  // console.log(data, "data");

  // Slide Handler for trending IndexXNFT's
  const nextButtonTrending = () => {
    trendingSlideRef.current.slickNext();
  };
  const previousButtonTrending = () => {
    trendingSlideRef.current.slickPrev();
  };
  

  const sliderSettingsUSD = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 2000, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          initialSlide: 2.2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const USDSlideRef = useRef();

  // console.log(data, "data");

  // Slide Handler for trending IndexXNFT's
  const nextButtonUSD = () => {
    USDSlideRef.current.slickNext();
  };
  const previousButtonUSD = () => {
    USDSlideRef.current.slickPrev();
  };
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setLoaderMessage("Loading...");
      const res = await axios.get("/sellerData.json");
      const sellerCardData = res.data;
      setSellerData(sellerCardData);
      setIsLoading(false);

    };
    fetchData();
  }, []);


  // Parse the query parameters from the URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const returnedFrom = urlParams.get('returnFrom');
    const blockchainFromUrl = urlParams.get('blockchain');
    const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
    const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
    const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
    const lsreturnUrl = localStorage.getItem('returnUrl');
    const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
    if (handleConnectIndexxWallet) {
      // Update the user details state
      if (returnedFrom && email) {
        handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
      } else if (lsIsIndexxWallet) {
        handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
      }
    }
  }, [blockchain]);

  const history = useLocation();

  useEffect(() => {
    const currentPath = history.pathname;
    const fetchBitcoinBlackNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinBlack);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinblack/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setBitcoinBlackNFTs(sorted);

    }
    const fetchBitcoinBlueNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinBlue);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinblue/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
     //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
     const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setBitcoinBlueNFTs(sorted);

    }
    const fetchBitcoinPurpleNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinPurple);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinpurple/output/" +
            filename;
        }

        return { ...obj, metadata: parsedMetadata };
      });
     //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
     const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setBitcoinPurpleNFTs(sorted);
      // const soldItems = sorted.filter(item => item.sold === true);
      // console.log(soldItems, "purple sold");
      // const unsoldItems = sorted.filter(item => item.sold === false);
      // setBitcoinPurpleNFTs(unsoldItems);
      // setsold(soldItems);

    }
    const fetchBitcoinPinkNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinPink);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinpink/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
    //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
    const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setBitcoinPinkNFTs(sorted);

    }
    const fetchBitcoinGreenNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinGreen);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoingreen/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      // console.log(parsedArray, "green");
     //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
     const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setBitcoinGreenNFTs(sorted);

    }
    const fetchBitcoinOrangeNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.bitcoinOrange);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Bitcoin/bitcoinorange/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      // const sorted = [...parsedArray].sort((a, b) => {
      //   const numA = parseInt(a.metadata.name.match(/\d+/)[0], 10);
      //   const numB = parseInt(b.metadata.name.match(/\d+/)[0], 10);
      //   return numA - numB;
      // });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setBitcoinOrangeNFTs(sorted);
    }

    const fetchEthereumBlackNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumBlack);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/black/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
     //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
     const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setEthereumBlackNFTs(sorted);
    }
    const fetchEthereumBlueNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumBlue);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/blue/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
     //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
     const sorted = parsedArray.sort((a, b) => {
      const editionA = (a.metadata).edition;
      const editionB = (b.metadata).edition;
      return editionA - editionB;
    });
      setEthereumBlueNFTs(sorted);
    }
    const fetchEthereumPurpleNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumPurple);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/purple/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setEthereumPurpleNFTs(sorted);
    }
    const fetchEthereumPinkNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumPink);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/pink/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setEthereumPinkNFTs(sorted);
    }
    const fetchEthereumGreenNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumGreen);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/green/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setEthereumGreenNFTs(sorted);

    }
    const fetchEthereumOrangeNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.ethereumOrange);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/Ethereum/orange/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = parsedArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setEthereumOrangeNFTs(sorted);
    }



    const fetchXUSDBlackNFTData = async () => {
      const result = await getAllNFTMinted(contractAddress.XUSDNFT);
      let data = result;
      const parsedArray = data.map(obj => {
        let parsedMetadata = JSON.parse(obj.metadata);
        if (parsedMetadata?.image !== null && parsedMetadata?.image !== undefined)
        //parsedMetadata.image = parsedMetadata.image.replace("ipfs://", "https://ipfs.io/ipfs/");
        {
          // Find the last occurrence of the "/" character
          const lastSlashIndex = parsedMetadata.image.lastIndexOf("/");
          // Extract the substring after the last "/" character
          const filename = parsedMetadata.image.substring(lastSlashIndex + 1);
          parsedMetadata.image =
            "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/USD/output/" +
            filename;
        }
        return { ...obj, metadata: parsedMetadata };
      });
      let newArray = updateRecords(parsedArray);
      //const sorted = parsedArray.sort((a, b) => a.token_id - b.token_id);
      const sorted = newArray.sort((a, b) => {
        const editionA = (a.metadata).edition;
        const editionB = (b.metadata).edition;
        return editionA - editionB;
      });
      setUsdNFTs(sorted);
    }

    function updateRecords(records) {
      for (let record of records) {
        const metadata = (record.metadata);

        // Check if the metadata name is "SoRekt NFT #1"
        if (metadata.name === "SoRekt NFT #1") {
          // Update the token_uri property
          record.token_uri = "https://ipfs.moralis.io:2053/ipfs/QmSdaQXjSHcYAuCfBZyecJnWYejJRkzC2a5uu8LGY7pEG8/1.json";

          // Update the metadata property
          // Update the metadata property as an object
          record.metadata = {
            name: "XUSD NFT #1",
            edition: 1,
            image: "https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/xnfts/USD/output/1.png",
            description: "XNFT USD with supply around 10000 and value equality to one USD.",
            date: 1687499636971
          };

          // Exit the loop after updating
          break;
        }
      }
      return records;  
    }

    if (currentPath === "/collections/bitcoin-xnft/1") {
      setIsBitcoin(true);
      fetchBitcoinBlackNFTData();
      fetchBitcoinBlueNFTData();
      fetchBitcoinGreenNFTData();
      fetchBitcoinOrangeNFTData();
      fetchBitcoinPurpleNFTData();
      fetchBitcoinPinkNFTData();
    } else if (currentPath === "/collections/ethereum-xnft/2") {
      setIsEthereum(true);
      fetchEthereumBlackNFTData();
      fetchEthereumBlueNFTData();
      fetchEthereumPinkNFTData();
      fetchEthereumGreenNFTData();
      fetchEthereumPurpleNFTData();
      fetchEthereumOrangeNFTData();
    } else if (currentPath === "/collections/xusd-nft/3") {
      setIsXUSD(true);
      fetchXUSDBlackNFTData();
    }



  }, [])

  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setName(newValue);
  };

  // Modal
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // This will be deleted when the API endpoint added
  const filteredSeller = sellerData.filter((sd) => sd.id === '1');

  const MobileTabs = styled(Tabs)({
    border: 'none',
    backgroundColor: `${darkMode ? '#040404' : '#ffffff'}`,
    '& .MuiTabs-indicator': {
      backgroundColor: 'inherit',
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? '#ffffff' : '#040404'}`,
    backgroundColor: `${darkMode ? '#171c26' : '#EEEEEE'}`,
    borderRadius: '4px',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#0294FE',
      borderRadius: '4px',
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        mt: 11.5,
      }}
    >
      {/* <Navigation ref={navigationRef} darkMode={darkMode} blockchain={blockchain} setBlockchain={setBlockchain} /> */}
      {isLoading && (<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {darkMode ? (
          <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
        ) : (
          <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
        )}
      </div>)
      }
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                }`,
            }}
          >
            {/* {"My Profile"} */}
            {isBitcoin ? `Bitcoin` : isEthereum ? `Ethereum` : `XUSD`}{` Collection`}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >

      </Box>
      {!isMobile &&
        <Box className={styles.topNavigationBox}>
          <Box sx={{ cursor: "pointer" }} onClick={() => window.history.back()}>
            <FiChevronLeft fontSize={"1.5rem"} color={darkMode ? "#ffffff" : "#121212"} />
          </Box>
          <Typography className={styles.topNavigationTypo} component="div">
            <Typography
              variant="h6"
              component="p"
              sx={{ cursor: "pointer" }}
              zIndex={2}
              color={`${darkMode ? "#ffffff" : "#121212"} !important`}
              onClick={() => window.history.back()}
            >
              {isBitcoin ? `Bitcoin` : isEthereum ? `Ethereum` : `XUSD`}{` Collection`}
            </Typography>
          </Typography>
        </Box>}
      {!isMobile ? (
        <Box zIndex={100} color={darkMode ? '#ffffff' : '#121212'}>
          <Box
            bgcolor={darkMode ? '#121212' : '#EEEEEE'}
            sx={{
              borderRadius: '10px',
            }}
          >
            {filteredSeller.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                    } 100%), url(${ProfileCover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: 5,
                  borderRadius: '10px',
                }}
              >
                <Box>
                  <Box
                    display='flex'

                    maxHeight={true}
                  >
                    <Avatar
                      // src={fs.sellerImage}
                      src={isBitcoin ? btclogo : isEthereum ? ETHlogo : USDlogo}
                      alt={fs.sellerName}
                      sx={{ width: 70, height: 70, marginBottom: -10 }}
                    /> <br />


                  </Box>

                  <Stack
                    mt={2}
                    mb={2}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      gap={1}
                    >
                      <Typography color="secondary" variant="h5">
                        {isBitcoin ? "Bitcoin IndexXNFT" : isEthereum ? "Ethereum IndexXNFT" : "XUSD"}
                      </Typography>
                      {/* <Typography color="secondary" variant="h5">
                        ({fs.sellerUserName})
                      </Typography> */}
                    </Stack>
                    {/* <Typography
                      color="secondary"
                      mt={1}
                      variant='body2'
                      textAlign='center'
                      lineHeight={2}
                    >
                     {name}
                    </Typography> */}
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              bgcolor={darkMode ? '#171C26' : '#EEEEEE'}
              sx={{
                borderRadius: "10px",

                mb: 4,
              }}
            >
              {(isBitcoin || isEthereum) ?
                (<Tabs
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                  centered
                >
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    // label={isBitcoin ? `Bitcoin` : isEthereum ? `Ethereum` : `XUSD`}
                    label={isBitcoin ? `Bitcoin` : `Ethereum`}
                  />
                  {/* {(isBitcoin || isEthereum) && ( */}
                  {/* <> */}
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Purple`}
                  />
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Blue`}
                  />
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Green`}
                  />
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Pink`}
                  />

                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={`Orange`}
                  />
                  {/* </> */}
                  {/* )} */}
                </Tabs>
                ) :
                (<Tabs
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                  centered
                >
                  <Tab
                    sx={{
                      color: 'gray',
                      textTransform: 'capitalize',
                      display: 'block',
                      mx: 5,
                      zIndex: "601",
                    }}
                    label={isBitcoin ? `Bitcoin` : isEthereum ? `Ethereum` : `XUSD`}
                  />

                </Tabs>)
              }
            </Box>

            <Box sx={{ mx: -3, mt: -2 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Stack mb={3} direction="row" justifyContent="flex-end">
                  <Button
                    onClick={handleOpenModal}
                    sx={{ py: 1.5, px: 5, zIndex: "12" }}
                    variant="contained"
                    color="accent"
                    startIcon={<AddIcon />}
                  >
                    {t("CREATE")}
                  </Button>
                </Stack> */}
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                      
                        <>                
                          {bitcoinBlackNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinBlackNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinBlackNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </> 
                      
                      : isEthereum ? 
                      <>

                        {ethereumBlackNFTs.length > 0 && (
                          <Slider ref={trendingSlideRef} {...sliderSettings}>


                            {ethereumBlackNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2

                          }}
                        >
                          <Button onClick={previousButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {ethereumBlackNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <CollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                      </>
                       : 
                       <>

                        {usdNFTs.length > 0 && (
                          <Slider ref={USDSlideRef} {...sliderSettingsUSD}>


                            {usdNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <USDCollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2

                          }}
                        >
                          <Button onClick={previousButtonUSD} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonUSD} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {usdNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <USDCollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                        </>
                       
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                >
                    {[1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))}
                    </Grid>
                  ) : (
                    <>
                      {isBitcoin ?
                      <>                
                          {bitcoinPurpleNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinPurpleNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinPurpleNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </>
                      
                       : 
                        <>

                          {ethereumPurpleNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {ethereumPurpleNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2

                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {ethereumPurpleNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                        </>
                      }

                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                        <>                
                          {bitcoinBlueNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinBlueNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinBlueNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </>
                      :
                      <>

                        {ethereumBlueNFTs.length > 0 && (
                          <Slider ref={trendingSlideRef} {...sliderSettings}>


                            {ethereumBlueNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2
                          }}
                        >
                          <Button onClick={previousButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {ethereumBlueNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <CollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                      </>
                       
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                        <>                
                          {bitcoinGreenNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinGreenNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinGreenNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </>

                      : 
                      <>

                        {ethereumGreenNFTs.length > 0 && (
                          <Slider ref={trendingSlideRef} {...sliderSettings}>


                            {ethereumGreenNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2

                          }}
                        >
                          <Button onClick={previousButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {ethereumGreenNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <CollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                      </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>

              <TabPanel value={tabValue} index={4}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                      <>                
                          {bitcoinPinkNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinPinkNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinPinkNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </>

                      : 
                      <>

                        {ethereumPinkNFTs.length > 0 && (
                          <Slider ref={trendingSlideRef} {...sliderSettings}>


                            {ethereumPinkNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2

                          }}
                        >
                          <Button onClick={previousButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {ethereumPinkNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <CollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                      </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>

              <TabPanel value={tabValue} index={5}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                      <>                
                          {bitcoinOrangeNFTs.length > 0 && (
                            <Slider ref={trendingSlideRef} {...sliderSettings}>


                              {bitcoinOrangeNFTs?.map((sdc) => (
                                sdc.sold === true ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Slider>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              mt: 5,
                              mb:2
                            }}
                          >
                            <Button onClick={previousButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronLeft />
                              </Typography>
                            </Button>
                            <Button onClick={nextButtonTrending} color="secondary">
                              <Typography component="span" color="secondary" fontSize={25}>
                                <FiChevronRight />
                              </Typography>
                            </Button>
                          </Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 1, sm: 12, md: 12 }}
                          >


                            {bitcoinOrangeNFTs?.map((sdc) => (
                              sdc.sold === false ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Grid>
                      </>

                      : 
                      <>

                        {ethereumOrangeNFTs.length > 0 && (
                          <Slider ref={trendingSlideRef} {...sliderSettings}>


                            {ethereumOrangeNFTs?.map((sdc) => (
                              sdc.sold === true ? (
                                <CollectionCard
                                  darkMode={darkMode}
                                  key={sdc.token_id}
                                  mode={"new"}
                                  id={sdc.token_hash}
                                  sellerCollection={sdc}
                                />
                              ) : null
                            ))}

                          </Slider>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mt: 5,
                            mb:2

                          }}
                        >
                          <Button onClick={previousButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronLeft />
                            </Typography>
                          </Button>
                          <Button onClick={nextButtonTrending} color="secondary">
                            <Typography component="span" color="secondary" fontSize={25}>
                              <FiChevronRight />
                            </Typography>
                          </Button>
                        </Box>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 4 }}
                          columns={{ xs: 1, sm: 12, md: 12 }}
                        >


                          {ethereumOrangeNFTs?.map((sdc) => (
                            sdc.sold === false ? (
                              <CollectionCard
                                darkMode={darkMode}
                                key={sdc.token_id}
                                mode={"new"}
                                id={sdc.token_hash}
                                sellerCollection={sdc}
                              />
                            ) : null
                          ))}

                        </Grid>
                        </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ position: "relative", overflowX: "hidden", mt: -10 }}
          color={darkMode ? "#ffffff" : "#121212"}
        >
          <Box
            bgcolor={darkMode ? "#121212" : "#EEEEEE"}
            sx={{
              // borderRadius: "20px",
              // p: 1,
            }}
          >
            {filteredSeller.map((fs) => (
              <Box
                sx={{
                  backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${darkMode ? "#040404" : "#e3dcdc"
                    } 100%), url(${ProfileCover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: 2,
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    maxHeight={true}
                    flexDirection="row"
                  >
                    <Avatar
                      src={isBitcoin ? btclogo : isEthereum ? ETHlogo : USDlogo}
                      alt={fs.sellerName}
                      sx={{ width: 60, height: 60 }}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    mb={1}
                    mt={2}
                  >
                    <Typography variant="h5" color="secondary">
                      {isBitcoin ? "Bitcoin IndexXNFT" : isEthereum ? "Ethereum IndexXNFT" : "XUSD"}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: "10px",
                mt: 3,
                mb: 4,
              }}
            >
              {(isBitcoin || isEthereum) ?

                (<MobileTabs
                  variant="scrollable"
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                // centered
                >
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={isBitcoin ? `Bitcoin` : `Ethereum`}
                  // label={`${t("COLLECTIONS")} (${filteredSeller.map(
                  //   (fs) => fs.sellerItems?.length
                  // )})`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Purple`}
                  // label={`${t("CREATED")} (${filteredSeller.map(
                  //   (fs) =>
                  //     fs.sellerItems.filter((si) => si.tag === "createdItems")
                  //       ?.length
                  // )})`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Blue`}
                  // label={`${t("OWNED")} (${filteredSeller.map(
                  //   (fs) =>
                  //     fs.sellerItems.filter((si) => si.tag === "ownedItems")
                  //       ?.length
                  // )})`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Green`}
                  // label={`${t("IN_AUCTION")} (${filteredSeller.map(
                  //   (fs) =>
                  //     fs.sellerItems.filter((si) => si.tag === "auctionItems")
                  //       ?.length
                  // )})`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={`Pink`}
                  // label={`${t("IN_SALE")} (${filteredSeller.map(
                  //   (fs) =>
                  //     fs.sellerItems.filter((si) => si.tag === "inSale")?.length
                  // )})`}
                  />
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                    }}
                    label={`Orange`}
                  // label={`${t("IN_SALE")} (${filteredSeller.map(
                  //   (fs) =>
                  //     fs.sellerItems.filter((si) => si.tag === "inSale")?.length
                  // )})`}
                  />
                </MobileTabs>)
                :
                (<MobileTabs
                  variant="scrollable"
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tabValue}
                  onChange={handleChange}
                // centered
                >
                  <MobileTab
                    sx={{
                      textTransform: "capitalize",
                      display: "block",
                      mr: 2,
                    }}
                    label={isBitcoin ? `Bitcoin` : isEthereum ? `Ethereum` : `XUSD`}
                  // label={`${t("COLLECTIONS")} (${filteredSeller.map(
                  //   (fs) => fs.sellerItems?.length
                  // )})`}
                  />
                </MobileTabs>)
              }
            </Box>
            <Box sx={{ mx: -3 }}>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Stack mb={3} direction="row" justifyContent="flex-end">
                  <Button
                    onClick={handleOpenModal}
                    sx={{ py: 1.5, px: 5 }}
                    variant="contained"
                    color="accent"
                    startIcon={<AddIcon />}
                  >
                    Create
                  </Button>
                </Stack> */}
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <CollectionCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                      
                        <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinBlackNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinBlackNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinBlackNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                        
                      : isEthereum ? 
                      
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumBlackNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumBlackNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumBlackNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      
                      : 
                      
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {usdNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {usdNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <USDCollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {usdNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <USDCollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                        <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinPurpleNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinPurpleNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinPurpleNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                        
                      : 
                          <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumPurpleNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumPurpleNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumPurpleNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                          </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                                        
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinBlueNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinBlueNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinBlueNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                        
                      : 
                      
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumBlueNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumBlueNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumBlueNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 
                      
                        <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinGreenNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinGreenNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinGreenNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>

                      : 
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumGreenNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumGreenNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumGreenNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <div
                  style={{
                    position: 'fixed',
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => <ArtCardFB key={n} />)
                  ) : (
                    <>
                      {isBitcoin ? 
                        <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinPinkNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinPinkNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinPinkNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      : 
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumPinkNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumPinkNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumPinkNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                  }}
                >

                </div>
                {/* <Grid
                  container
                  spacing={{ xs: 2, md: 4 }}
                  columns={{ xs: 1, sm: 12, md: 12 }}
                > */}
                  {filteredSeller.length === 0 ? (
                    [1, 2, 3, 4, 5, 6].map((n) => (
                      <ArtCardFB darkMode={darkMode} key={n} />
                    ))
                  ) : (
                    <>
                      {isBitcoin ? 

                        <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {bitcoinOrangeNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {bitcoinOrangeNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {bitcoinOrangeNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      
                      : 
                      <>
                            <Box
                              sx={{
                                py: 5,
                                position: "relative",
                              }}
                            >
                              {ethereumOrangeNFTs.length > 0 && (
                                <Slider ref={trendingSlideRef} {...sliderSettings}>


                                  {ethereumOrangeNFTs?.map((sdc) => (
                                    sdc.sold === true ? (
                                      <CollectionCard
                                        darkMode={darkMode}
                                        key={sdc.token_id}
                                        mode={"new"}
                                        id={sdc.token_hash}
                                        sellerCollection={sdc}
                                      />
                                    ) : null
                                  ))}

                                </Slider>
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Button onClick={previousButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronLeft />
                                  </Typography>
                                </Button>
                                <Button onClick={nextButtonTrending} color="secondary">
                                  <Typography component="span" color="secondary" fontSize={25}>
                                    <FiChevronRight />
                                  </Typography>
                                </Button>

                              </Box>
                            </Box>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 4 }}
                              columns={{ xs: 1, sm: 12, md: 12 }}
                            >


                              {ethereumOrangeNFTs?.map((sdc) => (
                                sdc.sold === false ? (
                                  <CollectionCard
                                    darkMode={darkMode}
                                    key={sdc.token_id}
                                    mode={"new"}
                                    id={sdc.token_hash}
                                    sellerCollection={sdc}
                                  />
                                ) : null
                              ))}

                            </Grid>
                      </>
                      
                      }
                    </>
                  )}
                {/* </Grid> */}
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
      <CreateCollection
        open={openModal}
        handleClose={handleCloseModal}
        darkMode={darkMode}
      />
    </Box>
  );
};

export default MultiCollectionPage;



