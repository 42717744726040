import React, { useEffect, useState } from 'react';
import {
    Card, CardContent, Typography, Box, Grid, Container, Link,
    useMediaQuery,

} from '@mui/material';
import { useTheme } from "@emotion/react";

import { GradientButtonBlue } from '../../Utils/GradientButtons/GradientButtons';
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import { redeemValue, redeemXNFTValue } from '../../service/api';
import styles from './Redeem.module.css';
import SwalNotification from '../../components/Notification/SwalNotification';

const RedeemView = ({ data, darkMode, account, blockchain, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb, setIsLoading }) => {
    const { message, giftCard, options } = data;
    const [isRedeem, setIsRedeem] = useState(false);
    const [redeemData, setRedeemData] = useState(false);
    const [colorIndex, setColorIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        let type = data.giftCard.type;
        console.log(type, type.includes("ORANGE"))
        if (type.includes("BLACK")) setColorIndex(0);
        if (type.includes("PURPLE")) setColorIndex(1);
        if (type.includes("BLUE")) setColorIndex(2);
        if (type.includes("GREEN")) setColorIndex(3);
        if (type.includes("PINK")) setColorIndex(4);
        if (type.includes("ORANGE")) setColorIndex(5);
    }, [colorIndex]);

    const redeemVoucher = async () => {
        try {
            setIsLoading(true);
            let walletAddress;
            if (isIndexxWalletWeb) {
                walletAddress = indexxWalletAddress;
            } else if (account !== undefined && account !== null && account !== "") {
                console.log(' am')
                walletAddress = account;
            } else {
                // alert("Please connect your Wallet");
                SwalNotification(darkMode, "Please connect your Wallet", "");
                setIsLoading(false);
                return;
            }
            let results = await redeemXNFTValue(giftCard.voucher, walletAddress);
            if (results) {
                setRedeemData(results.data);
                setIsRedeem(true);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            console.log('err', err);
            setIsLoading(false);
        }
    }

    const color = [["", "#000000"], ["Purple", "#6700A6"], ["Blue", "#004DCD"], ["Green", "#00A151"], ["Pink", "#FF00B8"], ["Orange", "#FF5C00"]];
    // console.log(options, "Options");
    // console.log(options.redeemOptions, "redeemOptions");

    return (
        <>
            {!isRedeem ?
                (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="text" fontSize={"30px"}>{message}</Typography>
                    <Card sx={{ width: `${isMobile ? "278px" : "325px"}`, margin: '1em', textAlign: "center", }}>
                        <CardContent>
                            <Typography variant="h5" component="div">Greeting Card Details</Typography>
                            <Typography variant="body2" color="text.secondary">
                                Voucher: {giftCard?.voucher} <br />
                                {/* Pin: {giftCard.pin} <br /> */}
                                Amount: {giftCard?.amount} USD <br />
                                Type: {giftCard?.type} <br />
                                {/* Base Currency: {giftCard.baseCurrency} <br /> */}
                                {/* Date of Generation: {new Date(giftCard.dateOfGeneration).toLocaleString()} */}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography variant="h5" my={2}>Redeem Options</Typography>
                    <Grid container spacing={4} ml={isMobile ? -8 : -7} p={0}>
                        {options?.redeemOptions.map((option, index) => (
                            <Grid item xs={12} sm={4} key={index} p={0} sx={{ padding: 0 }}>
                                <Card sx={{ maxWidth: 345, width: `${isMobile ? "278px" : "325px"}`, margin: '1em', p: 0 }}>
                                    <CardContent p={0} sx={{ padding: 0 }}>
                                        {/* <img src="YOUR_IMAGE_SOURCE" alt="Option" /> */}
                                        <Box bgcolor={color[colorIndex][1]}>
                                            <Typography variant="h5" color="white" sx={{ textAlign: "center" }} py={2}>
                                                {giftCard.type.includes("ETH") ? "Ethereum" : "Bitcoin"}
                                                {" "}{color[colorIndex][0]}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }} mt={2}>
                                            {/* Value: {option.item.value} <br /> */}
                                            Value in USD: {Math.floor(option.item.valueInUSD * 100) / 100} <br />
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                Quantity: {option.quantity}
                                            </span>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box>
                        <Typography variant="h4" mt={3} sx={{ textAlign: "center" }}>
                            You Get
                        </Typography>
                        {/* <Typography variant="h5" my={3} sx={{ textAlign: "left" }}>
                            80% of the {options.usdValue}$ is converted into :
                        </Typography> */}
                        {options?.redeemOptions.map((option, index) => (
                            <>
                                {option.quantity !== 0 ? (
                                    <>
                                        <Typography variant="h6" sx={{ textAlign: "left" }}>
                                            {option.quantity}{" "}
                                            {giftCard.type === "BTC_BLACK" ? "Bitcoin XNFT" :
                                                giftCard.type === "BTC_BLUE" ? "Bitcoin Blue XNFT" :
                                                    giftCard.type === "BTC_PURPLE" ? "Bitcoin Purple XNFT" :
                                                        giftCard.type === "BTC_GREEN" ? "Bitcoin Green XNFT" :
                                                            giftCard.type === "BTC_PINK" ? "Bitcoin Pink XNFT" :
                                                                giftCard.type === "BTC_ORANGE" ? "Bitcoin Orange XNFT" :
                                                                    giftCard.type === "ETH_BLACK" ? "Ethereum XNFT" :
                                                                        giftCard.type === "ETH_BLUE" ? "Ethereum Blue XNFT" :
                                                                            giftCard.type === "ETH_PURPLE" ? "Ethereum Purple XNFT" :
                                                                                giftCard.type === "ETH_GREEN" ? "Ethereum Green XNFT" :
                                                                                    giftCard.type === "ETH_PINK" ? "Ethereum Pink XNFT" : "Ethereum Orange XNFT"} {color[index][0]}
                                            {" : "}
                                            <span style={{ float: "right" }}>
                                                {((Math.floor(option.item.valueInUSD * 100) / 100) * option.quantity).toFixed(2)} {"$"}
                                            </span>
                                        </Typography>
                                    </>
                                ) : null}
                            </>
                        ))}
                        <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Total :{" "}
                            <span style={{ float: "right" }}>
                                {((Math.floor(options.usdValue * 100) / 100)).toFixed(2)} {"$"}
                            </span>
                        </Typography>
                        <Typography variant="h5" mt={3} sx={{ textAlign: "left" }}>
                            15% of the  {((Math.floor(options.usdValue * 100) / 100)).toFixed(2)}$ is converted into :
                        </Typography>
                        <Typography variant="h6" mt={3} sx={{ textAlign: "left" }}>
                            <span style={{ float: "right" }}>
                                {Math.floor((options.remainingValueIntoINEX) * 100) / 100} INEX
                            </span>{" "}
                            {/* <span style={{ float: "right" }}>
                                {Math.floor(options.usdValue * 0.15 * 100) / 100} $
                            </span> */}
                        </Typography>
                    </Box>



                    {/* <Typography variant="h6" mt={3}>Remaining Value in Indexx Tokens (INEX) in USD: {Math.floor(options.remainingValue * 100) / 100}</Typography>
                    <Typography variant="h6" mb={2}>Total approximate token excepted: {Math.floor((options.remainingValue / 2) * 100) / 100}</Typography> */}
                    <GradientButtonBlue
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            redeemVoucher()
                        }}
                        sx={{
                            fontSize: "16px",
                            textTransform: "none",
                            width: "100%",
                            padding: "10px",
                            my: 3,
                        }}
                    >
                        <Typography variant="p" fontWeight={300} color="secondary">
                            Continue Redeem
                        </Typography>
                    </GradientButtonBlue>
                </Box>) :
                (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4">{redeemData.message}</Typography>
                        <Card sx={{ margin: '1em', width: `${isMobile ? "278px" : "650px"}`, }}>
                            <CardContent>
                                <Typography variant="h5" component="div">Details</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    NFT Value: {redeemData.options.usdValue}  USD <br />
                                    Token Value: {redeemData.tokenValue} USD <br />
                                    INEX tx: <Link href={`https://polygonscan.com/tx/${redeemData?.result?.INEXtx}`}
                                        sx={{
                                            color: '#0099cc',
                                            overflowWrap: "break-word",
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer">{redeemData?.result.INEXtx}</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Typography variant="h5">XNFT Transactions</Typography>
                        {redeemData.result.XNFTtx.map((tx, index) => (
                            <Card key={index} sx={{ width: `${isMobile ? "278px" : "650px"}`, margin: '1em' }}>
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Transaction {index + 1}: <Link href={`https://polygonscan.com/tx/${tx}`}
                                            sx={{
                                                color: '#0099cc',
                                                overflowWrap: "break-word",
                                            }}
                                            target="_blank"
                                            rel="noopener noreferrer">{tx}</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                )
            }
        </>
    );
};

export default RedeemView;
