/* eslint-disable no-unused-vars */
import DataContext from './DataContext';
import React, { useEffect, useState, useContext } from "react";
// From Material UI
import {
    GradientButtonPrimary,
    GradientButtonSecondary,
    GradientButtonBlue
} from "../../Utils/GradientButtons/GradientButtons";
import sold from "../../assets/sold_.png";
import xusdsold from "../../assets/Sold Horizontal.png";


import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    Container,
    Divider,
    Fade,
    IconButton,
    Modal,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// Custom Gradient button

// Icons
import LinkIcon from "@mui/icons-material/Link";
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";
import { HiTemplate } from "react-icons/hi";
import { IoCart } from "react-icons/io5";
import PolygonIcon from "../../assets/Icons/darkUIIcons/polygon.svg";
import IPFSIcon from "../../assets/Icons/darkUIIcons/ipfs.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { biddingData } from "./biddingData";

import BTC from '../../assets/tokenImages/BTC.svg';
import ETH from '../../assets/tokenImages/ETH.svg';
import USDlogo from "../../assets/collectionImages/USDlogo.png";
import inexlogo from "../../assets/inex new copy.png";

// Tabpanel
import { TabPanel } from "./TabPanel";
// Styles
import styles from "./SingleArtWork.module.css";
import contractAddresses from "../../contractData/contractsAddress.json";
import bitcoinBlack from "../../contractData/BitcoinBlackXNFT.json";
import bitcoinGreen from "../../contractData/BitcoinGreenXNFT.json";
import bitcoinPink from "../../contractData/BitcoinPinkXNFT.json";
import bitcoinPurple from "../../contractData/BitcoinPurpleXNFT.json";
import bitcoinOrange from "../../contractData/BitcoinOrangeXNFT.json";
import bitcoinBlue from "../../contractData/BitcoinBlueXNFT.json";
import ethereumBlack from "../../contractData/EthereumBlackXNFT.json";
import ethereumGreen from "../../contractData/EthereumGreenXNFT.json";
import ethereumPink from "../../contractData/EthereumPinkXNFT.json";
import ethereumPurple from "../../contractData/EthereumPurpleXNFT.json";
import ethereumOrange from "../../contractData/EthereumOrangeXNFT.json";
import ethereumBlue from "../../contractData/EthereumBlueXNFT.json";
import e_bitcoinBlack from "../../contractData/e_BitcoinBlackXNFT.json";
import e_bitcoinGreen from "../../contractData/e_BitcoinGreenXNFT.json";
import e_bitcoinPink from "../../contractData/e_BitcoinPinkXNFT.json";
import e_bitcoinPurple from "../../contractData/e_BitcoinPurpleXNFT.json";
import e_bitcoinOrange from "../../contractData/e_BitcoinOrangeXNFT.json";
import e_bitcoinBlue from "../../contractData/e_BitcoinBlueXNFT.json";
import e_ethereumBlack from "../../contractData/e_EthereumBlackXNFT.json";
import e_ethereumGreen from "../../contractData/e_EthereumGreenXNFT.json";
import e_ethereumPink from "../../contractData/e_EthereumPinkXNFT.json";
import e_ethereumPurple from "../../contractData/e_EthereumPurpleXNFT.json";
import e_ethereumOrange from "../../contractData/e_EthereumOrangeXNFT.json";
import e_ethereumBlue from "../../contractData/e_EthereumBlueXNFT.json";
import usdNFT from "../../contractData/XUSDNFT.json";
import e_usdNFT from "../../contractData/e_XUSDNFT.json";
import ipfsData from "../../contractData/ipfsData.json";
import { getCurrentMintNumberByAddress } from "../../service/nftServices";
import XNFTLoader_dark from "../../assets/loader_gif.gif";
import XNFTLoader_light from "../../assets/Xwhite BG.gif";
import { maticcanAddressURL, maticscanTxURL, etherscanAddressURL, etherscanTxURL, getCryptoPrice, maticTokenUrl, ethTokenUrl } from "../../service/api";
import { element } from 'prop-types';
import SwalNotification from '../../components/Notification/SwalNotification';
const ethers = require('ethers');
function OldCollection({ darkMode, blockchain, account, handleConnectIndexxWallet, indexxWalletEmail, indexxWalletObj, indexxWalletAddress, isIndexxWalletWeb, connectwall }) {
    const { largeData } = useContext(DataContext);
    console.log(largeData, "largeData");
    const artworkImage = largeData.metadata.image;
    const artworkTitle = largeData.metadata.name;
    const artworkDetails = largeData.metadata.description;
    let artworkPrice = largeData?.metadata?.name?.includes("Blue") ? 0.25 :
        (largeData?.metadata?.name?.includes("Purple") ? 0.5 :
            (largeData?.metadata?.name?.includes("Green") ? 0.1 :
                (largeData?.metadata?.name?.includes("Pink") ? 0.01 :
                    (largeData?.metadata?.name?.includes("Orange") ? 0.001 : 1))));;
    let artPriceDenomation = largeData?.name?.includes("Bitcoin") ? "BTC" : (largeData?.name?.includes("Ethereum") ? "ETH" : "USD");
    const creator = largeData?.minter_address;
    const creatorImage = "";
    const owner = largeData?.minter_address;
    const ownerImage = "";
    const uploaded = 5;
    const likes = 10;
    const contractAddress = largeData?.token_address;
    const currentMint = largeData?.token_id;
    const isSold = largeData.sold;
    // States
    const [openModal, setOpenModal] = React.useState(false);
    const [artPriceInUsd, setArtPriceInUsd] = useState(0);
    const [artPriceInMatic, setArtPriceInMatic] = useState(0);
    const [artPriceInEth, setArtPriceInEth] = useState(0);
    const [likeState, setLikeState] = useState(false);
    const [bookmarkState, setBookmarkState] = useState(false);
    const [tabValue, setTabValue] = useState(0); // setting tab value for changing
    const [contractAddr, setContractAddr] = useState();
    const [userCollection, setUserCollection] = useState();
    const [isLoading, setIsLoading] = useState(false); // Replace this with your actual loading state
    const [buySuccessfully, setBuySuccessFully] = useState(false);
    const [tx, setTx] = useState("");
    const [txUrl, setTxUrl] = useState("");
    const [isItemSold, setIsItemIsSold] = useState(false);
    // This div covers the whole page
    const fullPageOverlayStyles = {
        display: 'flex', // Using flexbox
        justifyContent: 'center', // Center items horizontally
        alignItems: 'center', // Center items vertically
        position: 'fixed', // Fixed position
        top: 0, // From top edge
        left: 0, // From left edge
        height: '100vh', // Full height
        width: '100vw', // Full width
        zIndex: 1000, // Overlay should be on top of all other elements
        backgroundColor: 'rgba(0,0,0,0.5)' // Semi-transparent
    };
    // This div covers the whole page
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const navigate = useNavigate();

    const handleProfileClick = (id) => {
        navigate(`/view-profile/${id}`, { replace: true });
    };

    const handleConnectDrop = () => {
        connectwall.current.click();
    }

    useEffect(() => {

        async function lastPrice() {

            // Define static conversion rates for BTC and ETH
            const btcToUsdRate = await getCryptoPrice('BTC'); // Conversion rate for BTC to USD
            const ethToUsdRate = await getCryptoPrice('ETH'); // Conversion rate for ETH to USD
            const maticToUsdRate = await getCryptoPrice('MATIC');
            // Extract the currency and amount from the artworkPrice string
            //const [amount, currency] = artworkPrice.split(" ");
            const amount = largeData?.metadata?.name?.includes("Blue") ? 0.25 :
                (largeData?.metadata?.name?.includes("Purple") ? 0.5 :
                    (largeData?.metadata?.name?.includes("Green") ? 0.1 :
                        (largeData?.metadata?.name?.includes("Pink") ? 0.01 :
                            (largeData?.metadata?.name?.includes("Orange") ? 0.001 : 1))));

            artworkPrice = amount;
            setIsItemIsSold(largeData?.sold);
            // Convert the price to USD based on the currency
            let usdPrice;
            let maticPrice;
            let ethPrice;
            if (largeData?.name?.includes("Bitcoin")) {
                usdPrice = parseFloat(amount) * btcToUsdRate.data.lastPrice;
                artPriceDenomation = "BTC";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
            } else if (largeData?.name?.includes("Ethereum")) {
                usdPrice = parseFloat(amount) * ethToUsdRate.data.lastPrice;
                artPriceDenomation = "ETH";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
            } else {
                // Invalid or for USD currency
                usdPrice = 1;
                artPriceDenomation = "USD";
                maticPrice = usdPrice / maticToUsdRate.data.lastPrice;
                ethPrice = usdPrice / ethToUsdRate.data.lastPrice;
            }

            setContractAddr(largeData?.token_address)

            // Add commas to the USD price
            // const formattedPrice = usdPrice.toLocaleString();

            // Return the price in USD with commas
            // setArtPriceInUsd(formattedPrice);
            setArtPriceInUsd(usdPrice);
            setArtPriceInMatic(maticPrice);
            setArtPriceInEth(ethPrice);
        }
        lastPrice();
    });

    // Parse the query parameters from the URL when the component mounts
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        const returnedFrom = urlParams.get('returnFrom');
        const blockchainFromUrl = urlParams.get('blockchain');
        const lsIsIndexxWallet = localStorage.getItem('isIndexxWebWallet');
        const lsIndexxWalletsObj = localStorage.getItem('indexxWebWallets');
        const lsIndexxWalletEmail = localStorage.getItem('indexxWebWalletEmail');
        const lsreturnUrl = localStorage.getItem('returnUrl');
        const lsblockchainFromUrl = localStorage.getItem('blockchainFromUrl');
        if (handleConnectIndexxWallet) {
            // Update the user details state
            if (returnedFrom && email) {
                handleConnectIndexxWallet(email, returnedFrom, blockchainFromUrl);
            } else if (lsIsIndexxWallet) {
                handleConnectIndexxWallet(lsIndexxWalletEmail, lsreturnUrl, blockchainFromUrl);
            }
        }
    }, [blockchain]);

    const viewItemOnScan = async () => {
        console.log(largeData)
        if (isItemSold) {
            let chainUrl = (blockchain === "Polygon") ? maticTokenUrl : ethTokenUrl;
            let tokenData = `${contractAddress}?a=${largeData?.metadata?.edition}`;
            window.open(chainUrl + tokenData, "_blank")
        } else {
            SwalNotification(darkMode, `This Item is not yet sold can't be viewed on ${(blockchain === "Polygon") ? "PolygonScan" : "EtherScan"}`, "");
        }
    }

    const viewItemOnIPFS = async () => {
        if (isItemSold) {
            let ipfsUrl = largeData?.token_uri;
            window.open(ipfsUrl, "_blank");
        } else {
            SwalNotification(darkMode, `This Item is not yet sold can't be viewed on IPFS`, "");
        }
    }

    const buyNow = async () => {
        if (isItemSold) {
            SwalNotification(darkMode, "This Item already sold", "Please Buy a different item.");
        } else {
            setIsLoading(true);
            if (blockchain === "" || blockchain === null || blockchain === undefined) {
                // alert('Please select blockchain');
                // SwalNotification( darkMode, "Please select blockchain", "");
                handleConnectDrop();
                setIsLoading(false);
                return;
            }
            let userWalletObj = [];
            if (isIndexxWalletWeb) {
                userWalletObj = JSON.parse(localStorage.getItem("indexxWebWallets"));
            }
            else if (account === "" || account === undefined || account == null || blockchain === null || blockchain === "" || blockchain === undefined) {
                // alert('Connect wallet');
                // SwalNotification( darkMode, "Please Connect wallet", "");
                handleConnectDrop();
                setIsLoading(false);
                return;
            }

            const amount = largeData?.metadata?.name?.includes("Blue") ? 0.25 :
                (largeData?.metadata?.name?.includes("Purple") ? 0.5 :
                    (largeData?.metadata?.name?.includes("Green") ? 0.1 :
                        (largeData?.metadata?.name?.includes("Pink") ? 0.01 :
                            (largeData?.metadata?.name?.includes("Orange") ? 0.001 : 1))));
            let currency = largeData?.name?.includes("Bitcoin") ? "BTC" : (largeData?.name?.includes("Ethereum") ? "ETH" : "USD");

            let contracts = [
                {
                    "address": contractAddresses.bitcoinBlack,
                    "abi": bitcoinBlack.abi
                },
                {
                    "address": contractAddresses.bitcoinBlue,
                    "abi": bitcoinBlue.abi
                },
                {
                    "address": contractAddresses.bitcoinGreen,
                    "abi": bitcoinGreen.abi
                },
                {
                    "address": contractAddresses.bitcoinPurple,
                    "abi": bitcoinPurple.abi
                },
                {
                    "address": contractAddresses.bitcoinPink,
                    "abi": bitcoinPink.abi
                },
                {
                    "address": contractAddresses.bitcoinOrange,
                    "abi": bitcoinOrange.abi
                },
                {
                    "address": contractAddresses.ethereumBlack,
                    "abi": ethereumBlack.abi
                },
                {
                    "address": contractAddresses.ethereumBlue,
                    "abi": ethereumBlue.abi
                },
                {
                    "address": contractAddresses.ethereumGreen,
                    "abi": ethereumGreen.abi
                },
                {
                    "address": contractAddresses.ethereumPurple,
                    "abi": ethereumPurple.abi
                },
                {
                    "address": contractAddresses.ethereumPink,
                    "abi": ethereumPink.abi
                },
                {
                    "address": contractAddresses.ethereumOrange,
                    "abi": ethereumOrange.abi
                },
                {
                    "address": contractAddresses.e_bitcoinBlack,
                    "abi": e_bitcoinBlack.abi
                },
                {
                    "address": contractAddresses.e_bitcoinBlue,
                    "abi": e_bitcoinBlue.abi
                },
                {
                    "address": contractAddresses.e_bitcoinGreen,
                    "abi": e_bitcoinGreen.abi
                },
                {
                    "address": contractAddresses.e_bitcoinPurple,
                    "abi": e_bitcoinPurple.abi
                },
                {
                    "address": contractAddresses.e_bitcoinPink,
                    "abi": e_bitcoinPink.abi
                },
                {
                    "address": contractAddresses.e_bitcoinOrange,
                    "abi": e_bitcoinOrange.abi
                },
                {
                    "address": contractAddresses.e_ethereumBlack,
                    "abi": e_ethereumBlack.abi
                },
                {
                    "address": contractAddresses.e_ethereumBlue,
                    "abi": e_ethereumBlue.abi
                },
                {
                    "address": contractAddresses.e_ethereumGreen,
                    "abi": e_ethereumGreen.abi
                },
                {
                    "address": contractAddresses.e_ethereumPurple,
                    "abi": e_ethereumPurple.abi
                },
                {
                    "address": contractAddresses.e_ethereumPink,
                    "abi": e_ethereumPink.abi
                },
                {
                    "address": contractAddresses.e_ethereumOrange,
                    "abi": e_ethereumOrange.abi
                },
                {
                    "address": contractAddresses.XUSDNFT,
                    "abi": usdNFT.abi
                },
                {
                    "address": contractAddresses.e_XUSDNFT,
                    "abi": e_usdNFT.abi
                }
            ]
            if (blockchain === "Ethereum")
                setTxUrl(etherscanTxURL)
            else
                setTxUrl(maticscanTxURL);
            try {
                if (account) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    // Set signer
                    const signer = provider.getSigner();

                    for (let index = 0; index < contracts.length; index++) {
                        const element = contracts[index];
                        if (element.address.toLocaleLowerCase() === contractAddr.toLocaleLowerCase()) {
                            const res = await getCurrentMintNumberByAddress(element.address, element.abi, currency, blockchain);

                            const xnft = new ethers.Contract(
                                element.address,
                                element.abi,
                                signer
                            );

                            let totalCostWei = ethers.utils.parseUnits(String(res.costValue), 'wei'); // Directly parse the cost value

                            let mintTxn;
                            let tokenUri = res.tokenUri.replace(/\/(\d+)\.json$/, `/${currentMint}.json`);
                            if ((blockchain === "Ethereum")) {
                                if (currency === "USD") {
                                    mintTxn = await xnft.mint(tokenUri, {
                                        value: ethers.utils.parseEther((Math.floor(artPriceInEth * 10000000) / 1000000).toString()),
                                        gasLimit: ethers.utils.hexlify(600000)
                                    });
                                } else {
                                    mintTxn = await xnft.mint(tokenUri, {
                                        value: ethers.utils.parseEther((artworkPrice).toString()),
                                        gasLimit: ethers.utils.hexlify(600000)
                                    });
                                }
                            } else if (blockchain === "Polygon") {
                                if (currency === "USD") {
                                    mintTxn = await xnft.mint(tokenUri, {
                                        value: ethers.utils.parseEther((artPriceInMatic).toString()),
                                        gasLimit: ethers.utils.hexlify(600000)
                                    });
                                } else {
                                    const maticToSend = ethers.utils.parseEther(totalCostWei.toString());
                                    mintTxn = await xnft.mint(tokenUri, {
                                        value: maticToSend,
                                        gasLimit: ethers.utils.hexlify(600000)
                                    });
                                }
                            } else {
                                console.error('Unsupported blockchain or currency'); // Handle other cases
                                SwalNotification(darkMode, "Unsupported blockchain or currency", "");
                                setIsLoading(false);
                                return;
                            }

                            xnft.provider
                                .waitForTransaction(mintTxn.hash)
                                .then(async function (mintTxn) {
                                    if (mintTxn.status) {
                                        setBuySuccessFully(true);
                                        setTx(mintTxn.transactionHash)
                                        setIsLoading(false);
                                    } else {
                                        console.error('Transaction failed');
                                        SwalNotification(darkMode, "Transaction failed", "");
                                        setIsLoading(false);
                                        return;
                                    }
                                })
                                .catch(error => {
                                    console.error('Error waiting for transaction:', error);
                                });
                        }
                    }
                } else if (isIndexxWalletWeb) {
                    const providerURL = blockchain === "Ethereum" ? "https://eth-sepolia.g.alchemy.com/v2/9Hh6pgUeB9vYdDs2a_k-onYczSP8AeYN" : blockchain === "Polygon" ? "https://polygon-mumbai.g.alchemy.com/v2/NTVLlKPYGVR2mdmlpb0epJ_pgJaa3t0u" : "";
                    const provider = new ethers.providers.JsonRpcProvider(providerURL);
                    const requiredWallet = userWalletObj.find(x => x.coinName === blockchain);
                    // Set Wallet
                    const privateKey = requiredWallet.coinPrivateKey;
                    if (privateKey) {
                        const wallet = new ethers.Wallet(privateKey, provider);
                        for (let index = 0; index < contracts.length; index++) {
                            const element = contracts[index];
                            if (element.address.toLocaleLowerCase() === contractAddr.toLocaleLowerCase()) {
                                const res = await getCurrentMintNumberByAddress(element.address, element.abi, currency, blockchain);
                                const xnft = new ethers.Contract(
                                    element.address,
                                    element.abi,
                                    wallet
                                );
                                let totalCostWei = ethers.utils.parseUnits(String(res.costValue), 'wei'); // Directly parse the cost value

                                let mintTxn;
                                let tokenUri = res.tokenUri.replace(/\/(\d+)\.json$/, `/${currentMint}.json`);
                                if ((blockchain === "Ethereum")) {
                                    if (currency === "USD") {
                                        mintTxn = await xnft.mint(tokenUri, {
                                            value: ethers.utils.parseEther((Math.floor(artPriceInEth * 10000000) / 1000000).toString()),
                                            gasLimit: ethers.utils.hexlify(600000)
                                        });
                                    } else {
                                        mintTxn = await xnft.mint(tokenUri, {
                                            value: ethers.utils.parseEther((artworkPrice).toString()),
                                            gasLimit: ethers.utils.hexlify(600000)
                                        });
                                    }
                                } else if (blockchain === "Polygon") {
                                    if (currency === "USD") {
                                        mintTxn = await xnft.mint(tokenUri, {
                                            value: ethers.utils.parseEther((artPriceInMatic).toString()),
                                            gasLimit: ethers.utils.hexlify(600000)
                                        });
                                    } else {
                                        const maticToSend = ethers.utils.parseEther(totalCostWei.toString());
                                        mintTxn = await xnft.mint(tokenUri, {
                                            value: maticToSend,
                                            gasLimit: ethers.utils.hexlify(600000)
                                        });
                                    }
                                } else {
                                    console.error('Unsupported blockchain or currency'); // Handle other cases
                                    SwalNotification(darkMode, "Unsupported blockchain or currency", "");
                                    setIsLoading(false);
                                    return;
                                }

                                xnft.provider
                                    .waitForTransaction(mintTxn.hash)
                                    .then(async function (mintTxn) {
                                        if (mintTxn.status) {
                                            setBuySuccessFully(true);
                                            setTx(mintTxn.transactionHash)
                                            setIsLoading(false);
                                        } else {
                                            console.error('Transaction failed');
                                            SwalNotification(darkMode, "Transaction failed", "");
                                            setIsLoading(false);
                                            return;
                                        }
                                    })
                                    .catch(error => {
                                        console.error('Error waiting for transaction:', error);
                                    });
                            }
                        }
                    } else {
                        // alert(`Wallet not connected properly. Please connect and try again later`);
                        SwalNotification(darkMode, "Wallet not connected properly", "Please connect and try again later");
                        setIsLoading(false);
                    }
                }
            } catch (err) {
                console.log('err', err);
                // alert(err?.data?.message !== undefined ? err.data.message : `Insufficient funds in wallet address on ${blockchain} blockchain`)
                SwalNotification(darkMode, err?.data?.message !== undefined ? err.data.message : `Insufficient funds in wallet address on ${blockchain} blockchain`, "");
                setIsLoading(false);
            }
        }
    }


    useEffect(() => {
        setUserCollection(largeData)
    }, [largeData])




    // Tab handler
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [loading, setLoading] = useState(true);
    // Custom Mobile Tabs
    const MobileTabs = styled(Tabs)({
        border: "none",
        backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
        "& .MuiTabs-indicator": {
            backgroundColor: "inherit",
        },
    });

    const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        "&.Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#0294FE",
            borderRadius: "4px",
            fontWeight: theme.typography.fontWeightMedium,
        },
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        // Artwork details information
        <Container>
            {isLoading && (
                <div style={fullPageOverlayStyles}>
                    {darkMode ? (
                        <img width="350px" height="350px" src={XNFTLoader_dark} alt="loading..." />
                    ) : (
                        <img width="350px" height="350px" src={XNFTLoader_light} alt="loading..." />
                    )}
                </div>
            )}

            <Modal
                sx={{ zIndex: 500000 }}
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box
                        bgcolor={darkMode ? "#171c26" : "#EEEEEE"}
                        className={
                            !isMobile ? styles.modalStyleWeb : styles.modalStyleMobile
                        }
                    >
                        <Typography
                            className={styles.itemDetailsModalTitle}
                            color="secondary"
                            variant="h6"
                            component="div"
                        >
                            <Typography component="span" sx={{ mt: 0.3 }} color={darkMode ? "#ffffff" : "#121212"}>
                                <HiTemplate fontSize={"1.5rem"} />
                            </Typography>
                            <Typography variant="h6" component="span" mt={-0.2}>
                                {t("ITEM_DETAILS")}
                            </Typography>
                        </Typography>
                        <Typography
                            // This is global styles
                            className={
                                !isMobile
                                    ? styles.readMoreModalText
                                    : styles.readMoreModalTextMobile
                            }
                            variant="body2"
                            component="p"
                            color="secondary"
                            lineHeight={2}
                            height={250}
                            pr={2.5}
                        >
                            {artworkDetails}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Box mt={11} className={styles.detailsContainerBox}>
                <Box>
                    {/* Top navigation */}
                    {!isMobile ? (
                        <Box className={styles.topNavigationBox}>
                            <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => window.history.back()}
                            >
                                <FiChevronLeft fontSize={"1.5rem"}
                                    color={`${darkMode ? "#ffffff" : "#040404"}`}
                                />
                            </Box>
                            <Typography
                                className={styles.topNavigationTypo}
                                component="div"

                            >
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{ cursor: "pointer" }}
                                    zIndex={2}
                                    color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                                >
                                    {t("ITEM_DETAILS")}
                                </Typography>

                            </Typography>
                        </Box>
                    ) : (
                        <Box className={styles.topNavigationBoxMobile}>
                            <Box
                                width={"70px"}
                                height={"60px"}
                                mt={-1}
                                bgcolor={`${darkMode ? "#040404" : "#ffffff"}`}
                                display={'flex'}
                                alignContent={"center"}
                                flexWrap={"wrap"}
                            >
                                <FiChevronLeft
                                    onClick={() => window.history.back()}
                                    fontSize={"1.5rem"}
                                    color={`${darkMode ? "#ffffff" : "#040404"}`}
                                />
                            </Box>
                            <Box
                                sx={{
                                    position: "fixed",
                                    top: "3%",
                                    zIndex: "10000",
                                    width: "70%",
                                    display: "flex",
                                    justifyContent: "center",
                                    ml: 4,
                                }}
                            >
                                <Typography
                                    component="div"
                                    color="secondary"
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                    ml={4}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        component="p"
                                        sx={{ zIndex: 2 }}
                                    >
                                        {t("ITEM_DETAILS")}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {/* Details container */}
                    {!isMobile ? (
                        <Box className={styles.detailsContainer}>
                            <Box zIndex={10} height={"100%"}>
                                <Box
                                    sx={{
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        className={String(artworkTitle).includes('USD') ? styles.artDisplayImageUSD : styles.artDisplayImage}
                                        src={artworkImage}
                                        alt={artworkTitle}
                                        onLoad={() => setLoading(false)}
                                        loading="eager"
                                        style={{
                                            border: `${ String(artworkTitle).includes('Black') ? "2px solid #282828" : 0}`,
                                        }}
                                    />
                                    {isSold === true ? 
                                    <img
                                        className={String(artworkTitle).includes('USD') ? styles.overlayedUSD : styles.overlayed}
                                        src={String(artworkTitle).includes('USD') ? xusdsold : sold}
                                        alt={artworkTitle}
                                     />
                                     :null
                                    }
                                </Box>
                                <Box
                                    className={styles.artDisplayDetails}
                                    bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                    sx={{
                                        // mt: 3,
                                        // width: "100%"
                                        marginTop:"25%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 3,
                                        }}
                                    >
                                        <Box gap={1}>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("CONTRACT_ADDRESS")}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        component="p"
                                                        color={darkMode ? "#FFFFFF" : "#121212"}
                                                        mb={2}
                                                        fontWeight={500}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        color={darkMode ? "#FFFFFF" : "#121212"}
                                                        mb={2}
                                                        ml={1}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("TOKEN_ID")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {largeData?.metadata?.edition}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("TOKEN_STANDARD")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {"ERC721"}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("BLOCKCHAIN")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    Polygon
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("ROYALTY_FEE")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {5 + "%"}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("TRANSACTION_FEE")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    fontWeight={500}
                                                >
                                                    5%
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    className={styles.artDisplayDetails}
                                    sx={{
                                        mt: 2,
                                        // width: "100%"
                                    }}
                                >
                                    <Box className={styles.artDisplayDetailsContent}>
                                        <Button
                                            variant="contained"
                                            className={styles.artDisplayDetailsButton}
                                            sx={{
                                                p: 0,
                                                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                            }}
                                            onClick={viewItemOnIPFS}
                                        >
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <img
                                                    src={IPFSIcon}
                                                    alt="IPFS Icon"
                                                    width="20"
                                                    height={"20"}
                                                />
                                            </Typography>
                                            <Typography variant="body1" component="span">
                                                View on IPFS
                                            </Typography>
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                align="right"
                                            >
                                                <LinkIcon />
                                            </Typography>
                                        </Button>

                                        <Button
                                            variant="contained"
                                            className={styles.artDisplayDetailsButton}
                                            sx={{
                                                p: 0,
                                                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                            }}
                                            onClick={viewItemOnScan}
                                        >
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <img
                                                    src={PolygonIcon}
                                                    alt="Polygon Icon"
                                                    width="20"
                                                    height={"20"}
                                                />
                                            </Typography>
                                            <Typography variant="body1" component="span">
                                                {blockchain === "Polygon" ? "PolygonScan" : "EtherScan"}
                                            </Typography>
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <LinkIcon />
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                className={styles.detailsContainerContentBox}
                                bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                sx={{
                                    position: "relative",
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h6"
                                        component="h2"
                                        color="secondary.main"
                                        mb={4}
                                        fontWeight={500}
                                    >
                                        {artworkTitle}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        lineHeight={1.5}
                                        color="secondary.main"
                                        mb={2}
                                        textAlign="justify"
                                    >
                                        {artworkDetails?.length > 200
                                            ? artworkDetails.slice(0, 200)
                                            : artworkDetails}
                                        {artworkDetails.length > 200 && (
                                            <Button
                                                variant="text"
                                                onClick={handleOpenModal}
                                                sx={{ color: "#02C7FE", textTransform: "lowercase" }}
                                            >
                                                ...{t("READ_MORE")}
                                            </Button>
                                        )}
                                    </Typography>

                                    <Accordion
                                        disableGutters
                                        elevation={0}
                                        sx={{
                                            backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                            mb: 3,
                                            border: "none",
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <Typography component="span" color="secondary">
                                                    <BsChevronDown
                                                        fontSize={"1rem"}
                                                        style={{ marginTop: "5px" }}
                                                    />
                                                </Typography>
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                color="secondary"
                                                ml={-2}
                                                fontWeight={500}
                                            >
                                                {t("PROPERTIES")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                {largeData?.properties &&
                                                    largeData?.properties.length > 0 &&
                                                    largeData?.properties[0].key !== "" ? (
                                                    <>
                                                        {largeData.properties?.map((property) => (
                                                            <Box
                                                                className={styles.propBox}
                                                                bgcolor={darkMode ? "#040404" : "#ffffff"}
                                                            >
                                                                <Typography
                                                                    variant="button"
                                                                    component="p"
                                                                    color="#02C7FE"
                                                                >
                                                                    {property.key}
                                                                </Typography>
                                                                <Typography variant="body2" color="secondary">
                                                                    {property.value}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography variant="body2" color="secondary">
                                                            {t("NO_PROPERTIES_FOUND")}
                                                        </Typography>
                                                    </>
                                                )}
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>

                                    <>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                            mb={2}
                                            fontWeight={500}
                                        >
                                            {t(`PRICE`)}
                                        </Typography>
                                        {/* <Typography
                                            variant="h5"
                                            component="p"
                                            color="secondary.main"
                                            mb={3}
                                            fontWeight={500}
                                        >
                                            {artworkPrice} {" "} {artPriceDenomation}
                                        </Typography> */}
                                        <Typography
                                            variant="h5"
                                            component="p"
                                            color="secondary.main"
                                            mb={3}
                                            fontWeight={500}
                                        >
                                            {artPriceInUsd.toLocaleString()} USD

                                        </Typography>
                                    </>
                                    <>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                            mb={2}
                                            fontWeight={500}
                                        >
                                            {"Price to be paid"}
                                        </Typography>
                                        {blockchain === 'Ethereum' ?
                                            <Typography
                                                variant="h5"
                                                component="p"
                                                color="secondary.main"
                                                mb={3}
                                                fontWeight={500}
                                            >
                                                {artPriceDenomation === "USD" ? `${artPriceInEth} ETH` : `${artworkPrice} ETH`}
                                            </Typography>
                                            :
                                            blockchain === 'Polygon' ? <Typography
                                                variant="h5"
                                                component="p"
                                                color="secondary.main"
                                                mb={3}
                                                fontWeight={500}
                                            >
                                                {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"}
                                            </Typography>
                                                :
                                                <>
                                                    <Typography
                                                        variant="h5"
                                                        component="p"
                                                        color="secondary.main"
                                                        mb={3}
                                                        fontWeight={500}
                                                    >
                                                        {/* {artPriceDenomation === "USD" ? `${artPriceInEth} {" "} {"ETH"}`  : `${artworkPrice} {" "} {"ETH"}`} */}
                                                        {artPriceDenomation === "USD" ? `${artPriceInEth} ETH` : `${artworkPrice} ETH`}
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        component="p"
                                                        color="secondary.main"
                                                        mb={3}
                                                        fontWeight={500}
                                                    >
                                                        {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"}
                                                    </Typography>
                                                </>
                                        }
                                    </>
                                    <Accordion
                                        disableGutters
                                        elevation={0}
                                        sx={{
                                            backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                            mb: 3,
                                            border: "none",
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <Typography component="span" color="secondary">
                                                    <BsChevronDown
                                                        fontSize={"1rem"}
                                                        style={{ marginTop: "5px" }}
                                                    />
                                                </Typography>
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                color="secondary"
                                                ml={-2}
                                                fontWeight={500}
                                            >
                                                Breakdown
                                            </Typography>{" "}

                                            <img src={artPriceDenomation === "BTC" ? BTC : artPriceDenomation === "ETH" ? ETH : USDlogo} alt="currency" width={"25px"} height={"25px"}
                                                style={{ marginLeft: "5px", marginRight: "8px", marginTop: "3px", borderRadius: "15px" }} />{" "}
                                            <img src={inexlogo} alt="currency" width={"25px"} height={"25px"} style={{ marginTop: "3px" }} onClick={() => { window.location.href = "https://indexx.ai/indexx-exchange/token-details/inex" }} />

                                        </AccordionSummary>
                                        <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                            <Stack direction="column" spacing={2}>

                                                <Typography variant="body2" color="secondary" display={"block"}>
                                                    {"80% | "} {artPriceDenomation} {" XNFT Value"} {" : "} {`${(0.8 * artworkPrice).toFixed(3)} `}{artPriceDenomation}
                                                    {" ~ "} {`${(0.8 * artPriceInUsd).toLocaleString()} USD`}
                                                </Typography>
                                                <Typography variant="body2" color="secondary" display={"block"}>
                                                    {"20% | "} {"INEX Value"} {" : "} {`${((0.2 * artPriceInUsd) / 0.2).toLocaleString()} INEX`}
                                                    {" ~ "} {`${(0.2 * artPriceInUsd).toLocaleString()} USD`}
                                                </Typography>
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Divider className={styles.dividerBox} />
                                    <Box className={styles.singleArtCardInfo}>
                                        <Box className={styles.avatarBox}>
                                            <Box>
                                                <Avatar src={creatorImage} alt={creator} />
                                            </Box>
                                            <Stack direction="column">
                                                <Typography variant="caption" gutterBottom color="gray">
                                                    {t("CREATOR")}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    gutterBottom
                                                    color="secondary.main"
                                                    sx={{
                                                        textTransform: "lowercase",
                                                        maxWidth: "90px",
                                                    }}
                                                    noWrap={true}
                                                    onClick={() => handleProfileClick(creator)}
                                                >
                                                    {creator}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box className={styles.avatarBox}>
                                            <Box>
                                                <Avatar src={ownerImage} alt={owner} />
                                            </Box>
                                            <Stack direction="column">
                                                <Typography variant="caption" gutterBottom color="gray">
                                                    {t("OWNER")}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    gutterBottom
                                                    color="secondary.main"
                                                    sx={{
                                                        textTransform: "lowercase",
                                                        maxWidth: "90px",
                                                    }}
                                                    noWrap={true}
                                                    onClick={() => handleProfileClick(owner)}
                                                >
                                                    {owner}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Box>
                                    <Divider className={styles.dividerBox} />
                                    <Box className={styles.cardFooter}>
                                        <Stack direction="row" alignItems="center">
                                            <IconButton>
                                                <Typography component="span" color="secondary">
                                                    <HiOutlineClock className={styles.footerIcons} />
                                                </Typography>
                                            </IconButton>
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                color={darkMode ? "#ffffff" : "#121212"}
                                            >
                                                {uploaded + "hr ago"}
                                            </Typography>
                                        </Stack>
                                        {/* <Stack direction="row" alignItems="center" gap={2}>
                                            <Box className={styles.userInteractionBox}>
                                                <IconButton onClick={() => setLikeState(!likeState)}>
                                                    <Typography color="#e23e58" component="span">
                                                        <AiOutlineHeart className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                                <Typography
                                                    variant="caption"
                                                    component="span"
                                                    color={darkMode ? "#ffffff" : "#121212"}
                                                >
                                                    {likes}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton>
                                                    <Typography component="span" color="secondary">
                                                        <BsBookmark className={styles.footerIcons} />
                                                    </Typography>
                                                </IconButton>
                                            </Box>
                                        </Stack> */}
                                    </Box>
                                </Box>

                                <>
                                    {!buySuccessfully ?

                                        <Box className={styles.footerButtonContainer}>
                                            <GradientButtonBlue
                                                className={styles.gradientButtonClass}
                                                variant="contained"
                                                fullWidth={true}
                                                onClick={buyNow}
                                            >
                                                {/* <Typography
                                                    color="#ffffff"
                                                    component="span"
                                                    fontSize={20}
                                                    mt={0.5}
                                                >
                                                    <IoCart />
                                                </Typography> */}
                                                <Typography variant="body1" component="span">
                                                    {isItemSold ? t("SOLD") : t("BUY_NOW")}
                                                </Typography>
                                            </GradientButtonBlue>
                                        </Box>
                                        : (
                                            <Typography variant="caption"
                                                component="span"
                                                color={darkMode ? "#ffffff" : "#121212"}>
                                                Your XNFT tranasction is successfully completed. Transaction Hash: <a href={txUrl + tx} target="_blank" style={{ "color": "blueviolet" }}> {tx}</a>
                                            </Typography>

                                        )
                                    }
                                </>
                            </Box>
                        </Box>
                    ) : (
                        <Box className={styles.detailsContainerMobile}>
                            <Box zIndex={10}>
                                <img
                                    className={String(artworkTitle).includes('USD') ? styles.artDisplayImageUSDMobile : styles.artDisplayImageMobile}
                                    // className={styles.artDisplayImageMobile}
                                    src={artworkImage}
                                    alt={artworkTitle}
                                    loading="eager"
                                />
                                {isSold === true ? 
                                    <img
                                        // className={String(artworkTitle).includes('USD') ? styles.artDisplayImageUSD : styles.artDisplayImage}
                                        className={String(artworkTitle).includes('USD') ? styles.overlayedMobileUSD : styles.overlayedMobile}
                                        src={String(artworkTitle).includes('USD') ? xusdsold : sold}
                                        alt={artworkTitle}
                                        // width={"326px"}
                                        // height={"546px"}
                                     />
                                :null
                                }
                            </Box>
                            <Box className={styles.userInteractionBoxMobile}>
                                <Stack direction="row" alignItems="center">
                                    <IconButton>
                                        <Typography component="span" color="secondary">
                                            <HiOutlineClock className={styles.footerIcons} />
                                        </Typography>
                                    </IconButton>
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        color={darkMode ? "#ffffff" : "#121212"}
                                    >
                                        {uploaded} {t("HOURS_AGO")}
                                    </Typography>
                                </Stack>
                                {/* <Stack direction="row" alignItems="center" gap={2}>
                                    <Box className={styles.userInteractionBoxMobile}>
                                        <IconButton onClick={() => setLikeState(!likeState)}>
                                            <Typography component="span" color="secondary">
                                                <AiOutlineHeart className={styles.footerIcons} />
                                            </Typography>
                                        </IconButton>
                                        <Typography
                                            variant="caption"
                                            component="span"
                                            color={darkMode ? "#ffffff" : "#121212"}
                                        >
                                            {likes}
                                        </Typography>
                                    </Box>
                                    <Box mt={-0.5}>
                                        <IconButton>
                                            <Typography component="span" color="secondary">
                                                <BsBookmark className={styles.footerIcons} />
                                            </Typography>
                                        </IconButton>
                                    </Box>
                                </Stack> */}
                            </Box>
                            <Box>
                                <Box className={styles.cardFooterMobile}>
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        <Box>
                                            <Avatar src={creatorImage} alt={creator} />
                                        </Box>
                                        <Stack direction="column" alignItems="left">
                                            <Typography variant="caption" gutterBottom color="gray">
                                                {t("CREATOR")}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                                color="secondary.main"
                                                sx={{
                                                    textTransform: "lowercase",
                                                    maxWidth: "90px",
                                                }}
                                                noWrap={true}
                                                onClick={() => handleProfileClick(creator)}
                                            >
                                                {creator}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        <Box>
                                            <Avatar src={ownerImage} alt={owner} />
                                        </Box>
                                        <Stack direction="column" alignItems="left">
                                            <Typography variant="caption" gutterBottom color="gray">
                                                {t("OWNER")}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                gutterBottom
                                                color="secondary.main"
                                                sx={{
                                                    textTransform: "lowercase",
                                                    maxWidth: "90px",
                                                }}
                                                noWrap={true}
                                                onClick={() => handleProfileClick(owner)}
                                            >
                                                {owner}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        component="h2"
                                        color="secondary.main"
                                        mb={2}
                                    >
                                        {artworkTitle}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        lineHeight={1.8}
                                        mb={2}
                                        color="secondary.main"
                                        textAlign="left"
                                    >
                                        {artworkDetails?.length > 200
                                            ? artworkDetails?.slice(0, 200)
                                            : artworkDetails}
                                        {artworkDetails.length > 200 && (
                                            <Button
                                                variant="text"
                                                onClick={handleOpenModal}
                                                sx={{ fontSize: "11px", color: "#02C7FE" }}
                                            >
                                                ...{t("READ_MORE")}
                                            </Button>
                                        )}
                                    </Typography>

                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            component="p"
                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                            mb={1}
                                        >
                                            {t("PRICE")}
                                        </Typography>
                                        {/* <Typography
                                            variant="subtitle1"
                                            component="p"
                                            color="secondary.main"
                                            mb={1}
                                            fontWeight={500}
                                        >
                                            {artworkPrice} {" "} {artPriceDenomation}
                                        </Typography> */}
                                        <Typography
                                            variant="subtitle1"
                                            component="p"
                                            color="secondary.main"
                                            mb={1}
                                            fontWeight={500}
                                        >
                                            {artPriceInUsd.toLocaleString()} USD
                                        </Typography>
                                    </>
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            component="p"
                                            color={darkMode ? "#FFFFFF" : "#121212"}
                                            mb={1}
                                        >
                                            {"Price to be paid"}
                                        </Typography>
                                        {blockchain === 'Ethereum' ?
                                            <Typography
                                                variant="subtitle1"
                                                component="p"
                                                color="secondary.main"
                                                mb={1}
                                                fontWeight={500}
                                            >
                                                {artPriceDenomation === "USD" ? 10 : `${artworkPrice} ${artPriceDenomation}`}
                                            </Typography>
                                            :
                                            blockchain === 'Polygon' ? <Typography
                                                variant="subtitle1"
                                                component="p"
                                                color="secondary.main"
                                                mb={1}
                                                fontWeight={500}
                                            >
                                                {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"}
                                            </Typography>
                                                :
                                                <>
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="p"
                                                        color="secondary.main"
                                                        mb={1}
                                                        fontWeight={500}
                                                    >
                                                        {artPriceDenomation === "USD" ? 10 : `${artworkPrice} ${artPriceDenomation}`}

                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="p"
                                                        color="secondary.main"
                                                        mb={1}
                                                        fontWeight={500}
                                                    >
                                                        {`${(artPriceInMatic).toLocaleString()}`} {" "} {"MATIC"}
                                                    </Typography>
                                                </>
                                        }
                                    </>
                                    <Accordion
                                        disableGutters
                                        elevation={0}
                                        sx={{
                                            // backgroundColor: `${darkMode ? "#171c26" : "#EEEEEE"}`,
                                            mb: 3,
                                            border: "none",
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <Typography component="span" color="secondary">
                                                    <BsChevronDown
                                                        fontSize={"1rem"}
                                                        style={{ marginTop: "5px" }}
                                                    />
                                                </Typography>
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                color="secondary"
                                                ml={-2}
                                                fontWeight={500}
                                            >
                                                Breakdown
                                            </Typography>{" "}

                                            <img src={artPriceDenomation === "BTC" ? BTC : artPriceDenomation === "ETH" ? ETH : USDlogo} alt="currency" width={"25px"} height={"25px"}
                                                style={{ marginLeft: "5px", marginRight: "8px", marginTop: "3px", borderRadius: "15px" }} />{" "}
                                            <img src={inexlogo} alt="currency" width={"25px"} height={"25px"} style={{ marginTop: "3px" }}
                                                onClick={() => { window.location.href = "https://indexx.ai/indexx-exchange/token-details/inex" }}
                                            />

                                        </AccordionSummary>
                                        <AccordionDetails sx={{ border: "none", ml: -2 }}>
                                            <Stack direction="column" spacing={2}>

                                                <Typography variant="body2" color="secondary" display={"block"}>
                                                    {"80% | "} {artPriceDenomation} {" XNFT Value"} {" : "} {`${(0.8 * artworkPrice).toFixed(3)} `}{artPriceDenomation}
                                                    {" ~ "} {`${(0.8 * artPriceInUsd).toLocaleString()} USD`}
                                                </Typography>
                                                <Typography variant="body2" color="secondary" display={"block"}>
                                                    {"20% | "} {"INEX Value"} {" : "} {`${((0.2 * artPriceInUsd) / 0.2).toLocaleString()} INEX`}
                                                    {" ~ "} {`${(0.2 * artPriceInUsd).toLocaleString()} USD`}
                                                </Typography>
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                <Box
                                    className={styles.artDisplayDetailsMobile}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <Box className={styles.artDisplayDetailsContentMob} gap={2}>
                                        <Button
                                            variant="contained"
                                            className={styles.artDisplayDetailsButtonMob}
                                            sx={{
                                                p: 0,
                                                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                            }}
                                            onClick={() => {
                                                window.open(largeData.file, "_blank");
                                            }}
                                        >
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <img
                                                    src={IPFSIcon}
                                                    alt="IPFS Icon"
                                                    width="20"
                                                    height={"20"}
                                                />
                                            </Typography>
                                            <Typography variant="body1" component="span">
                                                View on IPFS
                                            </Typography>
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                align="right"
                                            >
                                                <LinkIcon />
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={styles.artDisplayDetailsButtonMob}
                                            sx={{
                                                p: 0,
                                                backgroundColor: `${darkMode ? "#171C26" : "#EEEEEE"}`,
                                            }}
                                            onClick={viewItemOnScan}
                                        >
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <img
                                                    src={PolygonIcon}
                                                    alt="Polygon Icon"
                                                    width="20"
                                                    height={"20"}
                                                />
                                            </Typography>
                                            <Typography variant="body1" component="span">
                                                View on {blockchain === "Polygon" ? "PolygonScan" : "EtherScan"}
                                            </Typography>
                                            <Typography
                                                color="#ffffff"
                                                component="span"
                                                fontSize={20}
                                                mt={1}
                                                sx={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <LinkIcon />
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    className={styles.artDisplayDetailsMobile}
                                    bgcolor={`${darkMode ? "#171C26" : "#EEEEEE"}`}
                                    sx={{
                                        mt: 3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 3,
                                        }}
                                    >
                                        <Box gap={1}>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    Contract Address
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        component="p"
                                                        color={darkMode ? "#FFFFFF" : "#121212"}
                                                        mb={2}
                                                        fontWeight={350}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {contractAddress.substring(0, 6) + "..." + contractAddress.substring(contractAddress.length - 3)}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        color={darkMode ? "#FFFFFF" : "#121212"}
                                                        mb={2}
                                                        ml={1}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </Typography>
                                                </div>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("TOKEN_ID")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {largeData?.metadata?.edition}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("TOKEN_STANDARD")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {"ERC721"}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("BLOCKCHAIN")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    Polygon
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    {t("ROYALTY_FEE")}
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    mb={2}
                                                    fontWeight={500}
                                                >
                                                    {5 + "%"}
                                                </Typography>
                                            </Box>
                                            <Box className={styles.artDisplayDetailsContent}>
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    color="gray"
                                                >
                                                    Transaction Fee
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    color={darkMode ? "#FFFFFF" : "#121212"}
                                                    fontWeight={500}
                                                >
                                                    5%
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={styles.footerButtonContainer}>
                                    <GradientButtonBlue
                                        className={styles.gradientButtonClass}
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={buyNow}
                                    >
                                        <Typography
                                            color="#ffffff"
                                            component="span"
                                            fontSize={20}
                                            mt={0.5}
                                        >

                                        </Typography>
                                        <Typography variant="body1" component="span">
                                            {t("BUY_NOW")}
                                        </Typography>
                                    </GradientButtonBlue>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>

                {/* Tabs and panels*/}
                {!isMobile ? (
                    <Box className={styles.tabBox}>
                        <Box sx={{ width: "100%", mt: 16 }}>
                            <Box
                                bgcolor={`${darkMode ? "#171c26" : "#EEEEEE"}`}
                                borderRadius="10px"
                            >
                                <Tabs
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    value={tabValue}
                                    onChange={handleChange}
                                >
                                    <Tab
                                        sx={{ color: "gray", ml: 5, textTransform: "capitalize" }}
                                        label={t("RECENT_BID")}
                                    />
                                    <Tab
                                        sx={{ color: "gray", ml: 12, textTransform: "capitalize" }}
                                        label={t("HISTORY")}
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                                {/* Global tab styles */}
                                <div className="tabStyles">
                                    {biddingData[0].bidder.map((bd) => (
                                        <Box mb={2} key={bd.id}>
                                            <Typography
                                                variant="body1"
                                                className={styles.tabBoxContent}
                                                color={"secondary.main"}
                                            >
                                                <Avatar
                                                    // sx={{
                                                    //     border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                                                    //         }`,
                                                    // }}
                                                    src={bd.avatar}
                                                    alt={bd.name}
                                                />
                                                <Typography variant="body2" component="p">
                                                    {t("BIDDING_PRICE")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {bd.amount}
                                                    </span>{" "}
                                                    {t("BIDDING_BY")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                                                    {t("BIDDING_AT")} {bd.date} {bd.time}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <div className="tabStyles">
                                    {biddingData[0].acceptee.map((bd) => (
                                        <Box mb={2} key={bd.id}>
                                            <Typography className={styles.tabBoxContent}
                                                color={"secondary.main"}
                                            >
                                                <Avatar
                                                    // sx={{
                                                    //     border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                                                    //         }`,
                                                    // }}
                                                    src={bd.avatar}
                                                    alt={bd.name}
                                                />
                                                <Typography variant="body2" component="p">
                                                    {t("BIDDING_PRICE")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {bd.amount}
                                                    </span>{" "}
                                                    {t("BIDDING_BY")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                                                    {t("BIDDING_AT")} {bd.date} {bd.time}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    ))}
                                </div>
                            </TabPanel>
                        </Box>
                    </Box>
                ) : (
                    <Box width="90%" className={styles.tabBox}>
                        <Box mt={5} mb={-3}>
                            <Box ml={-2} mb={2}>
                                <MobileTabs
                                    darkMode={darkMode}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    value={tabValue}
                                    onChange={handleChange}
                                >
                                    <MobileTab
                                        sx={{ color: "gray", textTransform: "capitalize" }}
                                        label={t("RECENT_BID")}
                                    />
                                    <MobileTab
                                        sx={{ color: "gray", ml: 3, textTransform: "capitalize" }}
                                        label={t("HISTORY")}
                                    />
                                </MobileTabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                                <div className="tabStylesMobile">
                                    {biddingData[0].bidder.map((bd) => (
                                        <Box pr={2} my={3} key={bd.id}>
                                            <Typography
                                                className={styles.tabBoxMobile}
                                                variant="body2"
                                                color={"secondary.main"}
                                            >
                                                <Avatar
                                                    // sx={{
                                                    //     border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                                                    //         }`,
                                                    // }}
                                                    src={bd.avatar}
                                                    alt={bd.name}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    sx={{ fontSize: "12px", lineHeight: 2 }}
                                                    component="p"
                                                >
                                                    {t("BIDDING_PRICE")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {bd.amount}
                                                    </span>{" "}
                                                    {t("BIDDING_BY")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                                                    {t("BIDDING_AT")} {bd.date} {bd.time}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <div className="tabStylesMobile">
                                    {biddingData[0].acceptee.map((bd) => (
                                        <Box pr={2} my={3} key={bd.id}>
                                            <Typography
                                                variant="body1"
                                                className={styles.tabBoxMobile}
                                                color={"secondary.main"}
                                            >
                                                <Avatar
                                                    // sx={{
                                                    //     border: `2px solid ${darkMode ? "#ffffff" : "#02C7FE"
                                                    //         }`,
                                                    // }}
                                                    src={bd.avatar}
                                                    alt={bd.name}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    component="p"
                                                    sx={{ fontSize: "12px", lineHeight: 2 }}
                                                >
                                                    {t("BIDDING_PRICE")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {bd.amount}
                                                    </span>{" "}
                                                    {t("BIDDING_BY")}{" "}
                                                    <span style={{ fontWeight: "bold" }}>{bd.name}</span>{" "}
                                                    {t("BIDDING_AT")} {bd.date} {bd.time}
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    ))}
                                </div>
                            </TabPanel>
                        </Box>
                    </Box>
                )}
            </Box>

        </Container>
    );
};

export default OldCollection;
